import { KaeplaEventFilter } from '@kaepla/events';
import Timeline from '@mui/lab/Timeline';
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import { Box, LinearProgress, Paper, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { EventEntryHelperContext } from './contexts/EventEntryHelper.context';
import {
  EventLogColumnConfiguration,
  EventLogFilterContext,
} from './contexts/EventLogFilter.context';
import { EventLogFilter } from './features/EventLogFilter/EventLogFilter';
import { EventLogGroupList } from './features/EventLogGroupList/EventLogGroupList';
import { useEventEntryHelperContext } from './hooks/useEventEntryHelperContext';
import { useEventListInfiniteQuery } from './hooks/useEventListInfiniteQuery';
import { useEventLogFilters } from './hooks/useEventLogFilters';

interface Properties {
  initialFilters?: Partial<KaeplaEventFilter>;
  configuration?: EventLogColumnConfiguration[];
  groupBy: {
    day?: boolean;
    user?: boolean;
    project?: boolean;
    customer?: boolean;
    reseller?: boolean;
  };
  height: number;
}

export const EventLogTimeline: React.FC<Properties> = ({
  initialFilters,
  configuration: eventLogColumnConfiguration = [],
  groupBy,
  height,
}) => {
  const {
    clearAllFilters,
    clearFilter,
    disabledFilterColumns,
    hiddenFilterColumns,
    eventLogAvailableFilterColumns,
    eventLogFilters,
    setFilter,
  } = useEventLogFilters({
    initialFilters,
    eventLogColumnConfiguration,
  });

  const eventLogFilterContextValue = useMemo(
    () => ({
      eventLogFilters,
      eventLogAvailableFilterColumns,
      eventLogColumnConfiguration,
      disabledFilterColumns,
      hiddenFilterColumns,
      setFilter,
      clearFilter,
      clearAllFilters,
    }),
    [
      clearAllFilters,
      clearFilter,
      eventLogColumnConfiguration,
      disabledFilterColumns,
      hiddenFilterColumns,
      eventLogAvailableFilterColumns,
      eventLogFilters,
      setFilter,
    ],
  );

  const eventEntryHelperContextValue = useEventEntryHelperContext({ eventLogFilters, groupBy });

  const eventListQuery = useEventListInfiniteQuery({
    filterBy: eventLogFilters,
  });

  const [eventList, setEventList] = useState(
    eventListQuery.data?.pages.flatMap((page) => page.rows) ?? [],
  );
  useEffect(() => {
    if (eventListQuery.isFetched) {
      setEventList(eventListQuery.data!.pages.flatMap((page) => page.rows));
    }
  }, [eventListQuery.data, eventListQuery.isFetched]);

  const count = eventList.length ?? 0;
  const totalCount = eventListQuery.data?.pages[0]?.max ?? 0;

  const hasMoreEventList = count < totalCount;

  const onRefresh = useCallback(() => {
    void eventListQuery.refetch();
  }, [eventListQuery]);

  return (
    <EventLogFilterContext.Provider value={eventLogFilterContextValue}>
      <EventEntryHelperContext.Provider value={eventEntryHelperContextValue}>
        <EventLogFilter
          isRelative={true}
          isLoading={eventListQuery.isFetching ?? eventListQuery.isLoading}
          onRefresh={onRefresh}
        />
        <Box
          component={Paper}
          sx={{
            p: 2,
            height,
            overflow: 'auto',
          }}
        >
          {eventListQuery.isLoading && <LinearProgress />}
          <InfiniteScroll
            dataLength={eventList.length ?? 0} // This is important field to render the next data
            next={eventListQuery.fetchNextPage}
            hasMore={hasMoreEventList}
            loader={
              <Box>
                <Typography variant="body2">
                  {eventListQuery.isFetching || eventListQuery.isLoading ? (
                    'Loading... '
                  ) : (
                    <Box
                      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                      component="span"
                      onClick={() => {
                        void eventListQuery.fetchNextPage();
                      }}
                    >
                      click to load
                    </Box>
                  )}
                </Typography>
              </Box>
            }
            scrollableTarget="events-scrollable"
          >
            <Timeline
              sx={{
                p: 0,
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0.01,
                },
              }}
            >
              {eventList.length > 0 && (
                <EventLogGroupList eventList={eventList} groupBy={groupBy} />
              )}
            </Timeline>
          </InfiniteScroll>
        </Box>
      </EventEntryHelperContext.Provider>
    </EventLogFilterContext.Provider>
  );
};
