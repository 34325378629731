import { DistinctValueDefault } from '@kaepla/events';
import {
  Autocomplete,
  Avatar,
  Box,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';

import {
  EventLogFilterColumn,
  EventLogFilterContext,
} from '../../../../../../contexts/EventLogFilter.context';

interface Properties {
  column: EventLogFilterColumn;
  options: DistinctValueDefault[];
  isLoading: boolean;
  isDisabled: boolean;
  isHidden: boolean;
}

export const FilterItemDefault: React.FC<Properties> = ({
  column,
  options,
  isLoading,
  isDisabled,
  isHidden,
}) => {
  const theme = useTheme();
  const { eventLogFilters, setFilter, clearFilter } = React.useContext(EventLogFilterContext);

  const value = eventLogFilters[column]
    ? (options.find((v) => v.id === eventLogFilters[column]) ??
      ({
        id: eventLogFilters[column] as string,
        name: eventLogFilters[column] as string,
      } satisfies DistinctValueDefault))
    : null;
  const [open, setOpen] = React.useState(value === null);

  const onChange = React.useCallback(
    (_: React.SyntheticEvent, newValue: DistinctValueDefault | null) => {
      if (newValue === null) {
        clearFilter({ column });
        return;
      }

      setFilter({ column, value: newValue.id });
      setOpen(false);
    },
    [clearFilter, column, setFilter],
  );

  return (
    <Autocomplete
      id={`filter-${column}`}
      open={open}
      size="small"
      options={options}
      value={value}
      disableClearable={isDisabled}
      sx={{ width: 300 }}
      onChange={onChange}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionKey={(option) => option.id}
      getOptionLabel={(option) => option.name}
      loading={isLoading}
      renderInput={(parameters) => {
        return (
          <TextField
            color="primary"
            {...parameters}
            autoFocus
            label={column}
            variant="outlined"
            slotProps={{
              input: {
                ...parameters.InputProps,
                endAdornment: (
                  <>
                    {isLoading && <CircularProgress color="inherit" size={20} />}
                    {parameters.InputProps?.endAdornment}
                  </>
                ),
              },
            }}
          />
        );
      }}
      renderOption={(properties, option) => {
        const { key, ...optionProperties } = properties;
        return (
          <Box
            key={key}
            component="li"
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...optionProperties}
          >
            <Stack
              spacing={1}
              display={'flex'}
              direction={'row'}
              alignItems={'center'}
              height={'100%'}
            >
              <Avatar
                sx={{
                  width: 22,
                  height: 22,
                  bgcolor: 'transparent',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor:
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[300]
                      : theme.palette.grey[800],
                }}
                data-acronym={(option.name ?? '?').slice(0, 2)}
                alt={option.name}
                src={option.iconBase64}
                variant="square"
              />
              <Typography>{option.name}</Typography>
            </Stack>
          </Box>
        );
      }}
      disabled={isLoading || isHidden || (isDisabled && options.length < 2)}
    />
  );
};
