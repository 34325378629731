import { KaeplaDataSnapshot, KaeplaOpsUpdateStatus } from '@kaepla/types';
import ArchiveIcon from '@mui/icons-material/ArchiveOutlined';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import ExpirationIcon from '@mui/icons-material/EditCalendarOutlined';
import RenameIcon from '@mui/icons-material/EditOutlined';
import AdminIcon from '@mui/icons-material/Person2Outlined';
import AutomatedIcon from '@mui/icons-material/ScheduleOutlined';
import UnArchiveIcon from '@mui/icons-material/UnarchiveOutlined';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid2 as Grid,
  IconButton,
  IconButtonProps,
  Menu,
  MenuItem,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { DateTime } from 'luxon';
import numbro from 'numbro';
import prettyBytes from 'pretty-bytes';
import { useState } from 'react';
import TimeAgo from 'react-timeago';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { useAuth } from '../../../AuthReactProvider';
import { setDataSnapshotExpirationOnCall } from '../../../services/api/setDataSnapshotExpiration';
import { dataSnapshotMarkForDeletion } from '../../../services/firestore/dataSnapshotMarkForDeletion';
import { toggleArchiveDataSnapshot } from '../../../services/firestore/toggleArchiveDataSnapshot';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { simulationState } from '../../../services/recoil/nonpersistent/simulationState';
import { snapShotState } from '../../../services/recoil/nonpersistent/snapshotState';
import { kaeplaAssignmentState } from '../../../services/recoil/persistent/kaeplaAssignmentState';
import { knownUsersState } from '../../../services/recoil/persistent/knownUsersState';
import { useUserPreferences } from '../../../UserPreferencesProvider';
import { UserAvatar } from '../../features/UserAvatar';
import { GenericConfirmDialog } from '../../Layout/features/GenericDeleteConfirmDialog';

import { DataSnapshotRenameDialog } from './DataSnapshotRenameDialog';

interface Options {
  dataSnapShot: KaeplaDataSnapshot;
}

const ToggleArchive = styled((properties: IconButtonProps) => {
  const { ...other } = properties;
  return <IconButton {...other} />;
})(() => ({
  marginLeft: 'auto',
}));

export const DataSnapshotCard = ({ dataSnapShot }: Options) => {
  const theme = useTheme();
  const { kaeplaUser } = useAuth();
  const { setPreferences } = useUserPreferences();
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const project = useRecoilValue(projectState);
  const knownUsers = useRecoilValue(knownUsersState);
  const [snapShot, setSnapShot] = useRecoilState(snapShotState);
  const resetSnapshot = useResetRecoilState(snapShotState);
  const resetSimulation = useResetRecoilState(simulationState);
  const [open, setOpen] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const openExpiration = Boolean(anchorElement);

  const handleExpirationClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleExpirationClose = () => {
    setAnchorElement(null);
  };

  const handleSetExpiration = (expirationDate: Date) => {
    setDataSnapshotExpirationOnCall({
      params: {
        projectId: project.id,
        dataSnapshotId: dataSnapShot.id,
        expirationDate,
      },
    });
    handleExpirationClose();
  };

  const owner = knownUsers.find((user) => user.uid === dataSnapShot.createdBy);

  const isProjectOwnerOrAdmin = () => {
    return project?.ownedBy === kaeplaUser?.uid || kaeplaAssignment;
  };

  const timeUntilExpiration = () => {
    if (dataSnapShot.metaData) {
      return DateTime.fromMillis(
        Number.parseInt(dataSnapShot.metaData.expirationTime, 10),
      ).toJSDate();
    }
    return dataSnapShot.createdAt.toDate();
  };

  const backupRetentionTime = () => {
    if (dataSnapShot.retentionDate) {
      return dataSnapShot.retentionDate.toDate();
    }
    return dataSnapShot.createdAt.toDate();
  };

  const expiresInYears = () => {
    let expiration = dataSnapShot.createdAt;
    if (dataSnapShot.retentionDate) {
      expiration = dataSnapShot.retentionDate;
    }

    const years = DateTime.fromMillis(expiration.toMillis()).diffNow('years').years;
    return Math.ceil(years);
  };

  return (
    <Grid size={{ sm: 12, md: 6, lg: 4, xl: 3 }}>
      <DataSnapshotRenameDialog
        dataSnapShot={dataSnapShot}
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
      />
      <GenericConfirmDialog
        openDelete={openDelete}
        title="Delete Data Snapshot?"
        description="This action can not be reverted!"
        confirm="delete"
        handleCloseDelete={() => {
          setOpenDelete(false);
        }}
        processDelete={() => {
          void dataSnapshotMarkForDeletion({ project, dataSnapShot });
        }}
      />
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          border: '1px solid',
          borderColor: snapShot?.id === dataSnapShot.id ? theme.palette.info.light : 'transparent',
        }}
      >
        <CardHeader
          avatar={
            owner ? (
              <UserAvatar sx={{ mr: 1 }} user={owner} />
            ) : dataSnapShot.createdBy === 'kaepla-scheduler' ? (
              <AutomatedIcon fontSize="large" />
            ) : (
              <AdminIcon fontSize="large" />
            )
          }
          title={
            <Box>
              {dataSnapShot?.name && (
                <Typography variant="h5" color="textPrimary">
                  {dataSnapShot.name}
                </Typography>
              )}
              {!dataSnapShot?.name && (
                <Typography variant="h6" color="textPrimary">
                  {dataSnapShot.createdAt.toDate().toDateString()}
                  {' @ '}
                  {DateTime.fromJSDate(dataSnapShot.createdAt.toDate()).toFormat('hh:mm')}
                </Typography>
              )}
            </Box>
          }
          subheader={DateTime.fromMillis(dataSnapShot.createdAt.toMillis())
            .setLocale('en')
            .toLocaleString({
              ...DateTime.DATETIME_MED_WITH_SECONDS,
            })}
        />
        <Tooltip title={dataSnapShot.id}>
          <CardContent
            onMouseEnter={() => {
              if (dataSnapShot?.description) {
                setShowDescription(true);
              }
            }}
            onMouseLeave={() => {
              if (dataSnapShot?.description) {
                setShowDescription(false);
              }
            }}
          >
            <Box>
              {dataSnapShot?.description && showDescription && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    color: theme.palette.text.disabled,
                  }}
                >
                  {dataSnapShot.description}
                </Typography>
              )}
            </Box>
            {!showDescription && (
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body2" color="textSecondary">
                    {dataSnapShot.createdAt.toDate().toTimeString()}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {dataSnapShot.archived ? 'Archived' : ''}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body2" color="textSecondary">
                    auto-delete
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <TimeAgo date={timeUntilExpiration()} max={Number.MAX_SAFE_INTEGER} />
                  </Typography>
                </Box>
                {kaeplaAssignment && (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="body2" color="textSecondary">
                      backup retention
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <TimeAgo date={backupRetentionTime()} max={Number.MAX_SAFE_INTEGER} />
                    </Typography>
                  </Box>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body2" color="textSecondary">
                    <TimeAgo date={dataSnapShot.createdAt.toDate()} max={Number.MAX_SAFE_INTEGER} />
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {dataSnapShot.updateStatus}
                  </Typography>
                </Box>
                {dataSnapShot?.metaData && (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    {dataSnapShot?.metaData?.numBytes && (
                      <Typography variant="body2" color="textSecondary">
                        {prettyBytes(Number.parseInt(dataSnapShot?.metaData?.numBytes, 10)).replace(
                          ' ',
                          '',
                        )}
                      </Typography>
                    )}
                    {dataSnapShot?.metaData?.numRows && (
                      <Typography variant="body2" color="textSecondary">
                        {numbro(Number.parseInt(dataSnapShot?.metaData?.numRows, 10)).format({
                          average: true,
                          mantissa: 2,
                        })}{' '}
                        records
                      </Typography>
                    )}
                    {dataSnapShot?.dimensions && (
                      <Typography variant="body2" color="textSecondary">
                        {dataSnapShot.dimensions.length} dimensions
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </CardContent>
        </Tooltip>
        <CardActions disableSpacing>
          {dataSnapShot.updateStatus !== KaeplaOpsUpdateStatus.finished &&
            dataSnapShot.updateStatus !== KaeplaOpsUpdateStatus.failed && (
              <CircularProgress size={30} />
            )}
          {isProjectOwnerOrAdmin() && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
              <ToggleArchive
                aria-label={dataSnapShot.archived ? 'unarchive' : 'archive'}
                onClick={() => {
                  void toggleArchiveDataSnapshot({ project, dataSnapShot });
                }}
                disabled={dataSnapShot.markedForDeletion}
              >
                {dataSnapShot.archived ? <UnArchiveIcon /> : <ArchiveIcon color="primary" />}
              </ToggleArchive>
              <Divider sx={{ marginY: 1.5 }} orientation="vertical" flexItem />
              <Button
                size="small"
                variant="text"
                onClick={() => {
                  // void dataSnapshotRename({ project, dataSnapShot });
                  setOpen(true);
                }}
                endIcon={<RenameIcon />}
              >
                rename
              </Button>
              <Divider sx={{ marginY: 1.5 }} orientation="vertical" flexItem />
              <Button
                size="small"
                variant="text"
                disabled={dataSnapShot.markedForDeletion}
                onClick={() => {
                  setOpenDelete(true);
                }}
                endIcon={<DeleteIcon />}
              >
                {dataSnapShot.markedForDeletion ? 'Deleting...' : 'Delete'}
              </Button>
              <Divider sx={{ marginY: 1.5 }} orientation="vertical" flexItem />
              <IconButton
                type="button"
                aria-label="set expiration"
                size="small"
                color="primary"
                sx={{ marginLeft: 'auto' }}
                onClick={handleExpirationClick}
              >
                <ExpirationIcon fontSize="small" />
              </IconButton>
              <Menu
                id="expiration-menu"
                anchorEl={anchorElement}
                open={openExpiration}
                onClose={handleExpirationClose}
              >
                <MenuItem
                  onClick={() => {
                    handleSetExpiration(DateTime.now().plus({ years: 1 }).toJSDate());
                  }}
                  disabled={expiresInYears() >= 1}
                >
                  1 year
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleSetExpiration(DateTime.now().plus({ years: 3 }).toJSDate());
                  }}
                  disabled={expiresInYears() >= 3}
                >
                  3 year
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleSetExpiration(DateTime.now().plus({ years: 5 }).toJSDate());
                  }}
                  disabled={expiresInYears() >= 5}
                >
                  5 years
                </MenuItem>
                {kaeplaAssignment && (
                  <MenuItem
                    onClick={() => {
                      handleSetExpiration(DateTime.now().plus({ years: 7 }).toJSDate());
                    }}
                    disabled={expiresInYears() >= 7}
                  >
                    7 years
                  </MenuItem>
                )}
                {kaeplaAssignment && (
                  <MenuItem
                    onClick={() => {
                      handleSetExpiration(DateTime.now().plus({ years: 10 }).toJSDate());
                    }}
                    disabled={expiresInYears() >= 10}
                  >
                    10 years
                  </MenuItem>
                )}
                {kaeplaAssignment && (
                  <MenuItem
                    onClick={() => {
                      handleSetExpiration(DateTime.now().plus({ years: 15 }).toJSDate());
                    }}
                    disabled={expiresInYears() >= 15}
                  >
                    15 years
                  </MenuItem>
                )}
              </Menu>
            </Box>
          )}
          {snapShot?.id !== dataSnapShot.id && (
            <Button
              sx={{ marginLeft: 'auto' }}
              variant="contained"
              size="small"
              disabled={
                dataSnapShot.markedForDeletion ??
                dataSnapShot.updateStatus !== KaeplaOpsUpdateStatus.finished
              }
              onClick={() => {
                resetSimulation();
                setPreferences({ lastSimulationId: undefined });
                setSnapShot(dataSnapShot);
              }}
            >
              Select
            </Button>
          )}
          {snapShot?.id === dataSnapShot.id && (
            <Button
              sx={{ marginLeft: 'auto', color: theme.palette.info.main }}
              size="small"
              onClick={() => resetSnapshot()}
              endIcon={<CancelIcon />}
            >
              Selected
            </Button>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
};
