/* eslint-disable import/no-unassigned-import */
import {
  KaeplaGetDataParameters,
  KaeplaGridSetStored,
  KaeplaGridSetting,
  KaeplaProject,
} from '@kaepla/types';
import { Alert, Box, Paper, Typography } from '@mui/material';
import {
  ChartRef,
  ColDef,
  ColGroupDef,
  GridPreDestroyedEvent,
  GridReadyEvent,
  GridState,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  MenuItemDef,
  StateUpdatedEvent,
} from 'ag-grid-community';
import { AgAxisLabelFormatterParams, LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact, CustomNoRowsOverlayProps } from 'ag-grid-react';
import numbro from 'numbro';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';
import { v4 as uuidv4 } from 'uuid';

import { useAuth } from '../../../../../AuthReactProvider';
import { getDataForGridView } from '../../../../../services/api/getDataForGrid';
import { applicationState } from '../../../../../services/recoil/nonpersistent/applicationState';
import { dataGridSettings } from '../../../../../services/recoil/nonpersistent/dataGridSets';
import { dataGridState } from '../../../../../services/recoil/nonpersistent/dataGridState';
import { dataViewState } from '../../../../../services/recoil/nonpersistent/dataViewState';
import { matrixFilteredState } from '../../../../../services/recoil/nonpersistent/matrixFilteredState';
import { perspectiveState } from '../../../../../services/recoil/nonpersistent/perspectiveState';
import { projectState } from '../../../../../services/recoil/nonpersistent/projectState';
import { simulationState } from '../../../../../services/recoil/nonpersistent/simulationState';
import { snapShotState } from '../../../../../services/recoil/nonpersistent/snapshotState';
import { currentScopePathState } from '../../../../../services/recoil/persistent/currentScopePathState';
import { filterSettingsState } from '../../../../../services/recoil/persistent/filterSettingState';
import { filterSqlState } from '../../../../../services/recoil/persistent/filterSqlState';
import { KaeplaDataView } from '../../../../../typings/KaeplaDataView';
import { Filters } from '../../../../features/Filters/Filters';
import { cleanColumnName, getColumnAbbreviation } from '../../../../helpers/cleanColumnName';
import { snapshotColor } from '../../../../Theme/colors';
import { simulationDataColor } from '../../../defaults';

import { ExportDialog } from './ExportDialog';
import {
  initialAutoGroupColumnDefinition,
  initialColumnDefinition,
  initialSideBar,
} from './gridConfigs';
import { GridMenu } from './GridMenu/GridMenu';
import { GridSets } from './GridSets/GridSets';
import { getColumnDefinitions } from './helpers/getColumnDefinitions';

import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-quartz.css';

import './style.css';

LicenseManager.setLicenseKey(
  `Using_this_{AG_Grid}_Enterprise_key_{AG-064886}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Atrigam_GmbH}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Kaepla}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Kaepla}_need_to_be_licensed___{Kaepla}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{12_August_2025}____[v3]_[01]_MTc1NDk1MzIwMDAwMA==4fb0cd1dbd971b5836c40b4bead31a90`,
);

const contextMenuItems: (string | MenuItemDef)[] = [
  'copy',
  'copyWithHeaders',
  'copyWithGroupHeaders',
  'export',
  'chartRange',
  'pivotChart',
];

interface GOptions {
  project: KaeplaProject;
}

const getGridFeatures = ({ project: _project }: GOptions) => {
  const gridFeatures = {
    enableSelection: true,
    enableCharts: true,
    pivotMode: true, // let's enable pivoting for all
  };

  return gridFeatures;
};

const getContextMenuItems = () => {
  return contextMenuItems;
};

export const GridView = () => {
  const { kaeplaUser } = useAuth();
  // global
  const application = useRecoilValue(applicationState);
  const gridState = useRecoilValue(dataGridState);
  const setGridSettings = useSetRecoilState(dataGridSettings);
  const filterSettings = useRecoilValue(filterSettingsState);
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const simulation = useRecoilValue(simulationState);
  const snapshot = useRecoilValue(snapShotState);
  const project = useRecoilValue(projectState);
  const perspective = useRecoilValue(perspectiveState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const filterSql = useRecoilValue(filterSqlState);
  const [dataView, setDataView] = useRecoilState(dataViewState);

  // local
  const containerStyle = useMemo(() => ({ width: '100%', height: '90%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const gridReference = useRef<AgGridReact>(null);
  const [localGridState, setLocalGridState] = useState<GridState>(gridState);
  const [searchTerm, setSearchTerm] = useState<string>();
  const compareColor = simulation?.id ? simulationDataColor : snapshotColor;
  const [gridParameters, setGridParameters] = useState<KaeplaGetDataParameters>();
  const [downloading, setDownloading] = useState<boolean>();
  const [downloadSuccess, setDownloadSuccess] = useState<boolean>();
  const [downloadError, setDownloadError] = useState<string>();
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [gridVisible, setGridVisible] = useState(true);
  const [showSavedGrids, setShowSavedGrids] = useState(false);
  const [currentGridId, setCurrentGridId] = useState<string>();
  const [selectedGridSet, setSelectedGridSet] = useState<KaeplaGridSetStored | null>(null);
  const [tabNumber, setTabNumber] = useState(0);
  const gridFeatures = useMemo(() => getGridFeatures({ project }), [project]);
  const [pivotActive, setPivotActive] = useState(
    dataView === KaeplaDataView.Table ? false : gridFeatures.pivotMode,
  );
  const chartReference = useRef<ChartRef>();
  const [error, setError] = useState<string>();
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);

  const handleExportDialogOpen = () => {
    setExportDialogOpen(true);
  };

  const defaultColDefinition = useMemo(() => {
    return initialColumnDefinition;
  }, []);

  const autoGroupColumnDefinition = useMemo(() => {
    return initialAutoGroupColumnDefinition;
  }, []);

  const addPivotResultCols = useCallback(
    (response: unknown[]) => {
      if (!matrixFiltered?.dimensions?.dimensions) return;
      const pivotMode = gridReference.current!.api.isPivotMode();
      const pivotCols = gridReference.current!.api.getPivotColumns();
      const valueCols = gridReference.current!.api.getValueColumns();
      gridReference.current!.api.setPivotResultColumns(null);
      if (!pivotMode || pivotCols.length === 0) {
        // console.log('resetColumnGroupState!');
        gridReference.current!.api.setPivotResultColumns(null);
        return;
      }

      const dimensions = matrixFiltered.dimensions.dimensions;
      const columnDefs = getColumnDefinitions({
        dimensions,
        perspective,
        compareColor,
        project,
        dataView,
      }) as ColDef[];

      let groupHeaders = false;
      if (pivotMode && pivotCols.length > 0 && valueCols.length > 1) {
        groupHeaders = true;
      }

      const pivotFields = response[0]
        ? Object.keys(response[0]).filter((field) => field.includes('_P_'))
        : [];

      const makeColumnHeaderName = (field: string) => {
        if (!field.includes('_P_')) return getColumnAbbreviation(field);
        return getColumnAbbreviation(field.split('_P_')[0]) + '_' + field.split('_P_')[1];
      };
      const makeColumnHeaderNameForGroups = (field: string) => {
        if (!field.includes('_P_')) return getColumnAbbreviation(field);
        return getColumnAbbreviation(field.split('_P_')[0]);
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const pivotColGroupDefs: ColGroupDef[] = [];
      // see below, this is if we do not want to group the pivot columns
      const pivotColDefs: ColDef[] = [];
      pivotFields.forEach((field) => {
        const headerName = makeColumnHeaderName(field);
        let fieldName = field;
        if (field.includes('_P_')) fieldName = field.split('_P_')[0];
        const originalDefinition = columnDefs.find((c) => c.field === fieldName);
        const pivotColDefinition = {
          ...originalDefinition,
          headerName,
          field,
        };
        pivotColDefs.push(pivotColDefinition);
      });
      // this is for grouping the pivot columns
      pivotFields.forEach((field) => {
        if (field.includes('__Simulated') || field.includes('__Snapshot')) {
          return;
        }
        let colGroupName = field;
        if (field.includes('_P_')) colGroupName = field.split('_P_')[1];
        if (!pivotColGroupDefs.some((c) => c.headerName === colGroupName)) {
          pivotColGroupDefs.push({
            headerName: colGroupName,
            children: [],
          });
        }

        const headerName = makeColumnHeaderNameForGroups(field);
        let fieldName = field;
        if (field.includes('_P_')) fieldName = field.split('_P_')[0];
        const originalDefinition = columnDefs.find((c) => c.field === fieldName);
        const pivotColDefinition = {
          ...originalDefinition,
          headerName,
          field,
        };
        const colGroup = pivotColGroupDefs.find((group) => group.headerName === colGroupName);
        if (colGroup) {
          colGroup.children.push(pivotColDefinition);
        }
      });
      if (pivotColDefs.length === 0) return;

      // console.log('pivotColDefs', groupHeaders, pivotColGroupDefs);
      // supply pivot result columns to the grid
      gridReference.current!.api.setPivotResultColumns(
        groupHeaders ? pivotColGroupDefs : pivotColDefs,
      );
    },
    [compareColor, dataView, matrixFiltered?.dimensions?.dimensions, perspective, project],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const applyChartOptions = useCallback(() => {
    if (!chartReference?.current?.chart) return;
    const pivotColumns = gridReference
      .current!.api.getPivotColumns()
      ?.map((c) => c.getColDef().field);
    const valueColumns = gridReference
      .current!.api.getValueColumns()
      ?.map((c) => c.getColDef().field);
    if (!valueColumns[0] || !pivotColumns[0]) return;
    const title = `${cleanColumnName(valueColumns[0])} over ${cleanColumnName(pivotColumns[0])}`;
    chartReference.current.chart.update({
      mode: 'integrated',
      title: {
        text: title,
      },
      navigator: {
        enabled: true,
        height: 10,
      },
      axes: [
        {
          type: 'category',
          position: 'bottom',
          label: {
            formatter: ({ value }: Record<string, string>) => {
              return value === 'Forecasting' ? '== Forecasting ==' : value;
            },
          },
        },
        {
          type: 'number',
          position: 'left',
          label: {
            format: '$#{0>6.2f}',
          },
        },
      ],
    });
  }, []);

  const addPivotChart = useCallback(() => {
    // if (chartReference.current?.chart) {
    //   console.log('destroying chart');
    //   chartReference.current.destroyChart();
    // }

    if (dataView === KaeplaDataView.Grid) {
      chartReference.current = gridReference.current!.api.createPivotChart({
        chartType: 'stackedColumn',
        chartThemeOverrides: {
          common: {
            navigator: {
              enabled: true,
              height: 10,
            },
            axes: {
              number: {
                min: 0,
                label: {
                  formatter: (parameters: AgAxisLabelFormatterParams) => {
                    // return parameters.value * 100 + '%';
                    return (
                      '€' +
                      numbro(parameters.value).format({
                        average: true,
                        mantissa: 1,
                      })
                    );
                  },
                },
              },
              category: {
                label: {
                  formatter: (parameters: AgAxisLabelFormatterParams) => {
                    return cleanColumnName(parameters.value);
                  },
                },
              },
            },
          },
        },
      });
      // applyChartOptions();
    }
  }, [dataView]);

  const NoRowsOverlay = (
    properties: CustomNoRowsOverlayProps & {
      noRowsMessageFunc: () => { message: string; type: string };
    },
  ) => {
    const { message, type } = properties.noRowsMessageFunc();
    return (
      <Box>
        <Alert severity={type as 'error' | 'info' | 'warning'}>
          {type === 'error' && <Typography variant="body1">Error getting the data</Typography>}
          <Typography variant="body2">{message}</Typography>
        </Alert>
        {simulation && type === 'error' && (
          <Alert severity="info" title="Outdated simulation?">
            <Typography variant="body1">Outdated simulation?</Typography>
            <Typography variant="body2">You might be looking at an outdated simulation.</Typography>
            <Typography variant="body2">
              Try de-select the simulation or run an update on it.
            </Typography>
          </Alert>
        )}
      </Box>
    );
  };

  const noRowsOverlayComponentParameters = useMemo(() => {
    if (error !== undefined) {
      return {
        noRowsMessageFunc: () => ({ message: error, type: 'error' }),
      };
    }
    return {
      noRowsMessageFunc: () => ({ message: 'No data found.', type: 'warning' }),
    };
  }, [error]);

  const getRows = useCallback(
    (getRowsParameters: IServerSideGetRowsParams) => {
      if (!kaeplaUser?.uid) return;
      if (!currentScopePath) return;

      let currentVisibleColumns: string[] = [];

      const dataGridRequestParameters = getRowsParameters.request;

      const visibleColumnsPerState = gridReference.current!.api.getColumnState();
      currentVisibleColumns = visibleColumnsPerState
        ?.filter((c) => !c.hide)
        .map((c) => c.colId)
        .filter((c) => !c.includes('_P_')) // TODO: filter by is in available dimensions
        .map((c) => c);

      const parameters: KaeplaGetDataParameters = {
        uid: kaeplaUser.uid,
        scopePath: currentScopePath,
        projectId: project.id,
        simulationId: simulation?.id,
        snapshotId: snapshot?.id,
        dataGridRequestParameters,
        searchTerm,
        info: 'PivotView',
        selectedColumns: currentVisibleColumns,
      };

      if (filterSettings.isActive) {
        parameters.filterSql = filterSql;
      }

      setGridParameters(parameters);
      const callGetDataForGrid = async () => {
        setError(undefined);
        getRowsParameters.api.hideOverlay();
        // requestLog.log('dataGridRequestParameters', dataGridRequestParameters);
        // requestLog.log('selectedColumns', visibleColumns);
        const rowData = (await getDataForGridView({
          params: parameters,
          uid: kaeplaUser?.uid,
          setError: (backendError) => {
            setError(backendError);
            getRowsParameters.api.showNoRowsOverlay();
            return;
          },
        })) as unknown[];

        if (rowData.length === 0) {
          getRowsParameters.api.showNoRowsOverlay();
        }

        addPivotResultCols(rowData);

        if (rowData) {
          getRowsParameters.success({ rowData });
        } else {
          getRowsParameters.fail();
        }
      };
      void callGetDataForGrid();
    },
    [
      addPivotResultCols,
      currentScopePath,
      filterSettings.isActive,
      filterSql,
      kaeplaUser?.uid,
      project.id,
      searchTerm,
      simulation?.id,
      snapshot?.id,
    ],
  );

  const onSearchTermChange = useDebouncedCallback(() => {
    const dataSource: IServerSideDatasource = {
      getRows,
    };
    gridReference.current!.api.setGridOption('serverSideDatasource', dataSource);
  }, 1000);

  const onGridReady = useCallback(
    (_parameters_: GridReadyEvent<Record<string, unknown>>) => {
      if (matrixFiltered?.dimensions?.dimensions) {
        const dimensions = matrixFiltered?.dimensions?.dimensions;
        const columnDefs = getColumnDefinitions({
          dimensions,
          perspective,
          compareColor,
          project,
          dataView,
        });

        gridReference.current!.api.setGridOption('columnDefs', columnDefs);
        // other settings
        gridReference.current!.api.sizeColumnsToFit();
      }

      // register the datasource with the grid
      const dataSource: IServerSideDatasource = {
        getRows,
      };

      gridReference.current!.api.setGridOption('serverSideDatasource', dataSource);
    },
    [matrixFiltered?.dimensions?.dimensions, perspective, compareColor, project, dataView, getRows],
  );

  const onDisplayedColumnsChanged = useCallback(() => {
    // with purge:true we force the grid to reload the data
    // just loading the data which is needed would be way smoother
    // but introduces a lot of complexity
    const columns = gridReference.current!.api.getColumnState();

    const newVisibleColumns = columns
      ?.filter((c) => !c.hide)
      .map((c) => c.colId)
      .filter((c) => !c.includes('_P_')) // TODO: filter by is in available dimensions
      .map((c) => c);

    if (JSON.stringify(newVisibleColumns.sort()) !== JSON.stringify(visibleColumns.sort())) {
      gridReference.current!.api.refreshServerSide({ purge: true });
      setVisibleColumns(newVisibleColumns);
    }
  }, [visibleColumns]);

  const onFirstDataRendered = useCallback(() => {
    gridReference.current!.api.sizeColumnsToFit();

    if (!chartReference.current?.chart) {
      applyChartOptions();
    }
  }, [applyChartOptions]);

  const addGridStateToGridSet = useCallback(() => {
    if (dataView !== KaeplaDataView.Grid) return;
    setGridSettings((oldGridSets) => {
      const gridSet: KaeplaGridSetting = {
        id: uuidv4(),
        name: `Grid ${oldGridSets.length + 1}`,
        gridState: gridReference.current!.api.getState(),
      };
      return [...oldGridSets, gridSet];
    });
  }, [dataView, setGridSettings]);

  const persistGridState = useCallback(
    (parameters: StateUpdatedEvent) => {
      if (dataView !== KaeplaDataView.Grid) return;

      setLocalGridState(parameters.state);
      if (currentGridId && showSavedGrids) {
        setGridSettings((oldSettings) => {
          const newGridSets = [...oldSettings].map((gridSet) => {
            if (gridSet.id === currentGridId) {
              return { ...gridSet, gridState: parameters.state };
            }
            return gridSet;
          });
          return newGridSets;
        });
      }
    },
    [currentGridId, dataView, setGridSettings, showSavedGrids],
  );

  const loadGridState = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (gridSettings: KaeplaGridSetting) => {
      if (dataView !== KaeplaDataView.Grid) return;
      setGridVisible(false);
      gridReference.current!.api.destroy(); // this is needed!
      setLocalGridState(gridSettings.gridState);
      setCurrentGridId(gridSettings.id);
      setTimeout(() => {
        setGridVisible(true);
      }, 100);
    },
    [dataView],
  );

  const onGridPreDestroyed = useCallback(
    (parameters: GridPreDestroyedEvent) => {
      if (dataView !== KaeplaDataView.Grid) return;
      setLocalGridState(parameters.state);
    },
    [dataView, setLocalGridState],
  );

  // this re-renders the grid when the simulation, filter or scope changes
  useEffect(() => {
    if (!gridReference?.current?.api) {
      return;
    }
    const dataSource: IServerSideDatasource = {
      getRows,
    };

    // register the datasource with the grid
    gridReference.current.api.setGridOption('serverSideDatasource', dataSource);
  }, [getRows, matrixFiltered?.dimensions?.dimensions, simulation, filterSql, dataView]);

  return (
    <Box
      component={Paper}
      variant="outlined"
      borderRadius="3px 3px 0 0"
      sx={{ height: dataView === KaeplaDataView.Table ? 500 : '97%' }}
    >
      <ExportDialog
        open={exportDialogOpen}
        setOpen={setExportDialogOpen}
        gridReference={gridReference}
        setDownloading={setDownloading}
        setDownloadSuccess={setDownloadSuccess}
        setDownloadError={setDownloadError}
        gridParameters={gridParameters}
      />
      <GridMenu
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onSearchTermChange={onSearchTermChange}
        handleExportDialogOpen={handleExportDialogOpen}
        dataView={dataView}
        downloading={downloading}
        downloadSuccess={downloadSuccess}
        downloadError={downloadError}
        setDataView={setDataView}
        showSavedGrids={showSavedGrids}
        setShowSavedGrids={setShowSavedGrids}
        currentGridId={currentGridId}
        loadGridState={loadGridState}
        selectedGridSet={selectedGridSet}
        setSelectedGridSet={setSelectedGridSet}
        setTabNumber={setTabNumber}
        addPivotChart={addPivotChart}
        pivotActive={pivotActive}
        api={gridReference.current?.api}
      />
      {dataView === KaeplaDataView.Grid && (
        <Box sx={{ p: '10px', display: showSavedGrids ? 'block' : 'none' }}>
          <GridSets
            loadGridState={loadGridState}
            addGridStateToGridSet={addGridStateToGridSet}
            selectedGridSet={selectedGridSet}
            setSelectedGridSet={setSelectedGridSet}
            tabNumber={tabNumber}
            setTabNumber={setTabNumber}
          />
        </Box>
      )}
      {dataView === KaeplaDataView.Grid && (
        <Box sx={{ p: '10px', display: application.showFilter ? 'block' : 'none' }}>
          <Filters />
        </Box>
      )}
      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-quartz">
          {gridVisible && (
            <AgGridReact
              ref={gridReference}
              rowModelType="serverSide"
              // enableAdvancedFilter={dataView !== DataView.Table && true} // this will take a while to implement on the server side
              defaultColDef={defaultColDefinition}
              autoGroupColumnDef={autoGroupColumnDefinition}
              // pivotMode={false}
              serverSidePivotResultFieldSeparator={'_P_'}
              suppressAggFuncInHeader={true}
              alwaysMultiSort={true}
              enableCharts={dataView === KaeplaDataView.Table ? false : gridFeatures.enableCharts}
              cellSelection={
                dataView === KaeplaDataView.Table ? false : gridFeatures.enableSelection
              }
              pivotPanelShow="never"
              autoSizeStrategy={{ type: 'fitCellContents' }}
              popupParent={document.body}
              sideBar={dataView === KaeplaDataView.Table ? [] : initialSideBar}
              suppressExpandablePivotGroups={false}
              maxConcurrentDatasourceRequests={1}
              maxBlocksInCache={2}
              purgeClosedRowNodes={true}
              onGridReady={onGridReady}
              getContextMenuItems={getContextMenuItems}
              initialState={{ ...localGridState }}
              onGridPreDestroyed={onGridPreDestroyed}
              onStateUpdated={persistGridState}
              onFirstDataRendered={onFirstDataRendered}
              onDisplayedColumnsChanged={onDisplayedColumnsChanged}
              getChartToolbarItems={() => []}
              noRowsOverlayComponent={NoRowsOverlay}
              noRowsOverlayComponentParams={noRowsOverlayComponentParameters}
              onColumnPivotModeChanged={() => {
                const pivotMode = gridReference.current!.api.getGridOption('pivotMode');
                // console.log('pivot mode changed ->', pivotMode);
                setPivotActive(pivotMode ?? false);
                if (!pivotMode) {
                  gridReference.current!.api.setPivotColumns([]);
                  chartReference.current?.chart?.destroy();
                }
              }}
            />
          )}
        </div>
      </div>
    </Box>
  );
};
