import { KaeplaReseller } from '@kaepla/types';
import { Grid2 as Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { EventLogTimeline } from '../../../component/EventLogTimeline/EventLogTimeline.js';
import { knownResellersState } from '../../../services/recoil/nonpersistent/knownResellersState.js';
import { Layout } from '../../Layout/Layout.js';

export const ResellerEvents = () => {
  const knownResellers = useRecoilValue(knownResellersState);
  const [currentReseller, setCurrentReseller] = useState<KaeplaReseller | undefined>(
    knownResellers[0],
  );

  useEffect(() => {
    if (knownResellers.length > 0) {
      setCurrentReseller(knownResellers[0]);
    }
  }, [knownResellers]);

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid size={12}>
          {currentReseller && (
            <EventLogTimeline
              initialFilters={{
                resellerId: currentReseller.id,
              }}
              configuration={[
                {
                  column: 'resellerId',
                  disabled: true,
                  hidden: false,
                  defaultValues: knownResellers,
                },
              ]}
              groupBy={{ day: true, user: true, customer: true, project: true, reseller: false }}
              height={window.innerHeight - 250}
            />
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};
