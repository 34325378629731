import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import { clientEventService } from '@kaepla/events';
import { KaeplaProject, KaeplaProjectAssignment, KaeplaUser } from '@kaepla/types';
import ShowMinimalIcon from '@mui/icons-material/ArrowLeft';
import { Avatar, AvatarGroup, Box, Button, Skeleton, Stack } from '@mui/material';
import { clone } from 'rambda';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuth } from '../../../../../../AuthReactProvider.js';
import { watcherServiceKaepla } from '../../../../../../firebaseInit.js';
import { resendInvite } from '../../../../../../services/api/resendInviteEmail.js';
import { addFirestoreCollectionListener } from '../../../../../../services/firestore/addFirestoreCollectionListener.js';
import { createProjectAssignmentForEmail } from '../../../../../../services/firestore/createProjectAssignmentForEmail.js';
import { getUser } from '../../../../../../services/firestore/getUser.js';
import { removeProjectAssignment } from '../../../../../../services/firestore/removeProjectAssignment.js';
import { watcherKeysState } from '../../../../../../services/recoil/nonpersistent/watcherKeysState';
import { currentScopePathState } from '../../../../../../services/recoil/persistent/currentScopePathState.js';
import { AddTeamMember } from '../../../../../features/AddTeamMember.js';
import { AddTeamMemberToggle } from '../../../../../features/AddTeamMemberToggle.js';
import { UserAvatar } from '../../../../../features/UserAvatar.js';
import { logger } from '../../../../../helpers/logger.js';
import { avatarGroupSlot, maxAvatarsDefault, scrollStyle } from '../../../../defaults.js';

interface KaeplaUserWithAssignment extends KaeplaUser {
  assignmentId: string;
}
interface Options {
  project: KaeplaProject;
  disableInvites?: boolean;
  defaultPath?: string[];
}

export const ProjectTeam = ({ project, disableInvites, defaultPath }: Options) => {
  const { kaeplaUser } = useAuth();
  const currentScopePath = useRecoilValue(currentScopePathState);
  const setWatcherKeys = useSetRecoilState(watcherKeysState);
  const [team, setTeam] = useState<KaeplaUserWithAssignment[]>([]);
  const [self, setSelf] = useState<KaeplaUserWithAssignment>();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [maxAvatars, setMaxAvatars] = useState<number>(maxAvatarsDefault);
  const [projectTeamWatcherKey, setProjectTeamWatcherKey] = useState<string>();

  const openAddTeamMemberDialog = () => {
    setOpen(true);
  };

  const closeAddTeamMemberDialog = (email: string) => {
    setOpen(false);
    if (!kaeplaUser?.uid || email.length === 0 || !currentScopePath) return;
    const sendInvite = async () => {
      let emailToInvite = email.toLocaleLowerCase();
      if (!emailToInvite.includes('@')) {
        emailToInvite = `${email}@kaepla.atrigam.com`;
      }
      await createProjectAssignmentForEmail({
        project,
        email: emailToInvite,
        scopePath: currentScopePath,
        assignedBy: kaeplaUser.uid,
        complete: false,
      });

      void clientEventService.createEvent({
        assignmentScope: clientEventService.assignmentScope.PROJECT,
        eventGroup: clientEventService.eventGroup.PROJECTS,
        eventName: clientEventService.eventName.PROJECT_TEAM_INVITE_USER_TO_TEAM,
        uid: kaeplaUser.uid,
        projectId: project.id,
        customerId: project.customerId,
        resellerId: project.resellerId,
        scopePath: currentScopePath,
        payload: {
          affectedUserEmail: email,
        },
      });
    };
    void sendInvite();
  };

  const leaveProjectTeam = async (userWithAssignment: KaeplaUserWithAssignment) => {
    // unmount the project listener
    if (projectTeamWatcherKey) {
      watcherServiceKaepla.get().unsubscribe(projectTeamWatcherKey);
    }
    logger.log('watcher key', projectTeamWatcherKey);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    void clientEventService.createEvent({
      assignmentScope: clientEventService.assignmentScope.PROJECT,
      eventGroup: clientEventService.eventGroup.PROJECTS,
      eventName: clientEventService.eventName.PROJECT_TEAM_LEAVE_TEAM,
      uid: kaeplaUser?.uid,
      projectId: project.id,
      customerId: project.customerId,
      resellerId: project.resellerId,
      scopePath: currentScopePath,
      payload: {
        affectedUserUid: userWithAssignment.uid,
      },
    });
    removeProjectAssignment({ id: userWithAssignment.assignmentId });
  };

  const removeAssignmentFromUser = (user: KaeplaUser) => {
    let userWithAssignment = team.find((u) => u.uid === user.uid);
    if (!userWithAssignment && self?.uid === user.uid) {
      userWithAssignment = self; // we leave the team
      void leaveProjectTeam(userWithAssignment);
      return;
    }

    if (userWithAssignment) {
      // we remove another user from the team
      void clientEventService.createEvent({
        assignmentScope: clientEventService.assignmentScope.PROJECT,
        eventGroup: clientEventService.eventGroup.PROJECTS,
        eventName: clientEventService.eventName.PROJECT_TEAM_REMOVE_USER_FROM_TEAM,
        uid: kaeplaUser?.uid,
        projectId: project.id,
        customerId: project.customerId,
        resellerId: project.resellerId,
        scopePath: currentScopePath,
        payload: {
          affectedUserUid: userWithAssignment.uid,
        },
      });
      removeProjectAssignment({ id: userWithAssignment.assignmentId });
    }
  };

  const resendEmailInvite = (user: KaeplaUser) => {
    const userWithAssignment = team.find((t) => t.uid === user.uid);
    if (!userWithAssignment) return;
    void resendInvite({ params: { projectAssignmentId: userWithAssignment.assignmentId } });
  };

  // this is the assignmentListener
  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    setSelf(kaeplaUser as KaeplaUserWithAssignment);
    const scopePathStringified = JSON.stringify(currentScopePath);
    const fireStorePath = `projectAssignments`;
    const queryWhere: WhereClause[] = [];

    // on ProjectCard where we might not have project or scope yet
    if (currentScopePath) {
      queryWhere.push({
        fieldPath: 'scopePathStringified',
        opStr: '==',
        value: scopePathStringified,
      });
    }

    if (project?.id) {
      queryWhere.push({
        fieldPath: 'projectId',
        opStr: '==',
        value: project.id,
      });
    }

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      callback: (data) => {
        const _projectAssignments = data as KaeplaProjectAssignment[];
        const load = async () => {
          setLoading(true);
          const assignments = _projectAssignments.filter(
            (assignment) => assignment.projectId === project.id,
          );

          const _team: KaeplaUserWithAssignment[] = [];
          await Promise.all(
            assignments.map(async (assignment) => {
              const user = (await getUser({ uid: assignment.uid })) as KaeplaUserWithAssignment;
              user.assignmentId = assignment.id;
              _team.push(user);
            }),
          );

          const _self = _team.find((teamMember) => teamMember.uid === kaeplaUser.uid);
          setSelf(_self);
          // await new Promise((resolve) => setTimeout(resolve, 2000));
          const others = clone(_team).filter((teamMember) => teamMember.uid !== kaeplaUser.uid);
          setTeam(others);
          setLoading(false);
        };
        void load();
      },
      watcherKeyCallback: (key) => {
        setWatcherKeys((_watcherKeys) => {
          const newWatcherKeys = { ..._watcherKeys };
          const keyId = `projectTeam-${project.id}`;
          newWatcherKeys[keyId] = key;
          setProjectTeamWatcherKey(key);
          return newWatcherKeys;
        });
      },
      context: 'ProjectTeam',
    });
    return () => {
      logger.log('ProjectTeam Listener is unmounted!');
      unsubscribe();
    };
  }, [kaeplaUser?.uid, currentScopePath, kaeplaUser, project?.id, defaultPath, setWatcherKeys]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      data-testid="project-team"
      // divider={<Divider orientation="vertical" flexItem />}
      spacing={2}
    >
      <Box sx={showAll && team.length > 5 ? scrollStyle : { display: 'inherit' }}>
        <Box sx={{ pl: 1.5 }}>
          <AvatarGroup
            max={maxAvatars}
            spacing="small"
            slotProps={avatarGroupSlot}
            onClick={() => {
              setShowAll(true);
              setMaxAvatars(team.length + 1);
            }}
          >
            {self && (
              <UserAvatar
                user={self}
                project={project}
                self={true}
                star={(project.ownedBy ?? project.createdBy) === self.uid}
                removeCallback={removeAssignmentFromUser}
              />
            )}
            {loading && (
              <Avatar sx={{ width: 45, height: 45 }}>
                <Skeleton animation="wave" variant="circular" />
              </Avatar>
            )}
            {team?.map((user) => (
              <UserAvatar
                key={user.uid}
                user={user}
                project={project}
                star={(project.ownedBy ?? project.createdBy) === user.uid}
                removeCallback={removeAssignmentFromUser}
                resendInviteCallback={resendEmailInvite}
              />
            ))}
          </AvatarGroup>
        </Box>
      </Box>
      {showAll && (
        <Box
          component={Button}
          variant="outlined"
          data-testid="project-team-minimize"
          onClick={() => {
            setShowAll(false);
            setMaxAvatars(maxAvatarsDefault);
          }}
          sx={{
            p: 0,
            minWidth: 0,
            display: 'flex',
            alignItems: 'center',
            width: 10,
            cursor: 'pointer',
          }}
        >
          <ShowMinimalIcon />
        </Box>
      )}
      {!disableInvites && <AddTeamMemberToggle callback={openAddTeamMemberDialog} />}
      <AddTeamMember open={open} onClose={closeAddTeamMemberDialog} />
    </Stack>
  );
};
