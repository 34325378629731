import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { requestLog } from '../../Frontend/helpers/logger.js';

interface KOptions {
  params: Record<string, unknown>;
}
export const abortSimulationOnCall = ({ params }: KOptions) => {
  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const abortSimulation = httpsCallable(functions, 'abortSimulation', { timeout: 700_000 });

  requestLog.log(`abortSimulation({data:${JSON.stringify(params)}})`);
  void abortSimulation(params);
};
