import { KaeplaEvent } from '@kaepla/events';
import { Button, Stack } from '@mui/material';
import { ColDef, GetRowIdParams, ICellRendererParams } from 'ag-grid-community';
import { useCallback, useMemo, useState } from 'react';

import { AgGridReact } from '../../../Frontend/features/AgGrid/index.js';
import { EventLogFilterContext } from '../../../component/EventLogTimeline/contexts/EventLogFilter.context.js';
import { EventLogFilter } from '../../../component/EventLogTimeline/features/EventLogFilter/EventLogFilter.js';
import { useEventLogFilters } from '../../../component/EventLogTimeline/hooks/useEventLogFilters.js';
import { ColumnCreatedBy } from '../components/ColumnCreatedBy.js';

import { AGPagination } from './components/AGPagination.js';
import { EventDetailModal } from './components/EventDetailModal.js';
import { useEventListQuery } from './hooks/useEventListQuery.js';

export function EventList() {
  const {
    clearAllFilters,
    clearFilter,
    disabledFilterColumns,
    hiddenFilterColumns,
    eventLogAvailableFilterColumns,
    eventLogFilters,
    setFilter,
  } = useEventLogFilters({
    initialFilters: {},
    eventLogColumnConfiguration: [],
  });

  const eventLogFilterContextValue = useMemo(
    () => ({
      eventLogFilters,
      eventLogAvailableFilterColumns,
      eventLogColumnConfiguration: [],
      disabledFilterColumns,
      hiddenFilterColumns,
      setFilter,
      clearFilter,
      clearAllFilters,
    }),
    [
      clearAllFilters,
      clearFilter,
      disabledFilterColumns,
      hiddenFilterColumns,
      eventLogAvailableFilterColumns,
      eventLogFilters,
      setFilter,
    ],
  );

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  const eventListQuery = useEventListQuery({
    filterBy: eventLogFilters,
    batchSize: pageSize,
    page: page - 1,
  });

  // PAGINATION

  const eventList = eventListQuery.data?.rows ?? [];
  const rowMax = eventListQuery.data?.max ?? 0;

  const pagination = {
    page,
    pageSize,
    pageMax: Math.ceil(rowMax / pageSize),
    rowFrom: pageSize * (page - 1) + 1,
    rowTo: Math.min(pageSize * page, rowMax),
    rowMax,
  };

  const onRefresh = useCallback(() => {
    void eventListQuery.refetch();
  }, [eventListQuery]);

  const getRowId = useCallback((parameters: GetRowIdParams<KaeplaEvent>) => {
    return parameters.data.id;
  }, []);

  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [kaeplaEvent, setKaeplaEvent] = useState<KaeplaEvent>();

  const openDetailModal = useCallback((event: KaeplaEvent | undefined) => {
    if (!event) {
      return;
    }
    setKaeplaEvent(event);
    setDetailModalOpen(true);
  }, []);

  const closeDetailModal = useCallback(() => {
    setDetailModalOpen(false);
    setKaeplaEvent(undefined);
  }, []);

  const columnDefs: ColDef<KaeplaEvent>[] = [
    {
      field: 'id',
      colId: 'id',
      headerName: 'ID',
      flex: 1,
      cellRenderer: (parameters: ICellRendererParams<KaeplaEvent>) => {
        if (!parameters.data) return;
        return (
          <Button onClick={() => openDetailModal(parameters.data)} variant="text">
            {parameters.data.id}
          </Button>
        );
      },
    },
    {
      //
      // !!! TODO unify user id names
      //
      // createdBy in import list
      //
      field: 'uid',
      headerName: 'Created By',
      filter: 'agMultiColumnFilter',
      flex: 1,
      cellRenderer: (parameters: ICellRendererParams<KaeplaEvent>) => {
        return <ColumnCreatedBy createdBy={parameters.data?.uid ?? undefined} />;
      },
    },
    {
      headerName: 'Event Name',
      cellRenderer: (parameters: ICellRendererParams<KaeplaEvent>) => {
        return <span>{parameters.data?.eventName}</span>;
      },
    },
    {
      headerName: 'Event Group',
      cellRenderer: (parameters: ICellRendererParams<KaeplaEvent>) => {
        return <span>{parameters.data?.eventGroup}</span>;
      },
    },
    {
      headerName: 'Origin',
      cellRenderer: (parameters: ICellRendererParams<KaeplaEvent>) => {
        try {
          const origin = JSON.parse((parameters.data?.origin as string | undefined) ?? '');
          return <span>{origin.originType}</span>;
        } catch {}
        return null;
      },
    },
    {
      headerName: 'Version',
      cellRenderer: (parameters: ICellRendererParams<KaeplaEvent>) => {
        try {
          const origin = JSON.parse((parameters.data?.origin as string | undefined) ?? '');
          return <span>{origin.version}</span>;
        } catch {}
        return null;
      },
    },
    {
      headerName: 'Access Domain',
      cellRenderer: (parameters: ICellRendererParams<KaeplaEvent>) => {
        try {
          const origin = JSON.parse((parameters.data?.origin as string | undefined) ?? '');
          return <span>{origin.accessDomain}</span>;
        } catch {}
        return null;
      },
    },
    {
      field: 'dateTime',
      headerName: 'Created At',
      cellRenderer: (parameters: ICellRendererParams<KaeplaEvent>) => {
        if (!parameters.data) return;
        return <span>{parameters.data.dateTime}</span>;
      },
    },
  ];

  return (
    <Stack
      test-id="event-list"
      spacing={2}
      sx={{
        py: 2,
        display: 'flex',
        height: '80vh',
        flex: 1,
      }}
    >
      <Stack spacing={2} direction={'row'} alignItems={'center'}>
        <EventLogFilterContext.Provider value={eventLogFilterContextValue}>
          <EventLogFilter
            isRelative={false}
            isLoading={eventListQuery.isFetching ?? eventListQuery.isLoading}
            onRefresh={onRefresh}
          />
        </EventLogFilterContext.Provider>
      </Stack>

      <AgGridReact<KaeplaEvent>
        loading={eventListQuery.isLoading}
        rowData={eventList}
        rowModelType="clientSide"
        getRowId={getRowId}
        suppressPaginationPanel={true}
        columnDefs={columnDefs}
      />

      <AGPagination
        currentPage={pagination.page}
        maxPages={pagination.pageMax}
        setPage={setPage}
        currentPageSize={pagination.pageSize}
        setPageSize={setPageSize}
        pageSizeOptions={[100, 500]}
        rowFrom={pagination.rowFrom}
        rowTo={pagination.rowTo}
        maxRows={pagination.rowMax}
      />

      <EventDetailModal
        kaeplaEvent={kaeplaEvent}
        onClose={closeDetailModal}
        open={detailModalOpen}
      />
    </Stack>
  );
}
