import { clientEventService, GetKaeplaEventDistinctValuesOnCallParameters } from '@kaepla/events';
import { useQuery } from '@tanstack/react-query';

import { useCreateQueryKey } from '../../../hooks/useCreateQueryKey';

interface Options {
  parameters: GetKaeplaEventDistinctValuesOnCallParameters;
  isDisabled?: boolean;
}

export function useEventDistinctValuesQuery({ parameters, isDisabled = false }: Options) {
  const { queryKey } = useCreateQueryKey('eventDistinctValues', {
    column: parameters.column,
    ...parameters.filterBy,
  });
  const query = useQuery({
    queryKey,
    queryFn: () => {
      if (isDisabled) return;

      return clientEventService.getDistinctValues(parameters);
    },
  });

  return query;
}
