import { KaeplaEvent } from '@kaepla/events';
import { useMemo } from 'react';

import { getDay } from '../../../Frontend/helpers/getDay';

interface GroupedEvent {
  groupedBy: string;
  name: string;
  eventList: KaeplaEvent[];
  groupList: GroupedEvent[];
}

export interface KaeplaEventGroup {
  key: string;
  day: string;
  uid?: string;
  projectId?: string;
  customerId?: string;
  resellerId?: string;
  eventList: KaeplaEvent[];
  lastEvent: KaeplaEvent;
}

interface Options {
  eventList: KaeplaEvent[];
  groupBy: {
    day?: boolean;
    user?: boolean;
    project?: boolean;
    customer?: boolean;
    reseller?: boolean;
  };
}

export const useGroupEventList = ({ eventList, groupBy }: Options) => {
  const eventGroups = useMemo(() => {
    const grouped = groupEvents({ eventList, groupBy });

    return flattenGroupedEvents({ groupedEvents: grouped });
  }, [eventList, groupBy]);
  return eventGroups;
};

const flattenGroupedEvents = ({
  groupedEvents,
  result = [],
}: {
  groupedEvents: GroupedEvent[];
  result?: KaeplaEventGroup[];
}) => {
  groupedEvents.forEach((group) => {
    if (group.groupList.length > 0) {
      flattenGroupedEvents({ groupedEvents: group.groupList, result });
      return;
    }

    if (group.eventList.length === 0) {
      return;
    }

    const day = getDay(group.eventList[0].dateTime);
    const uid = group.eventList[0].uid ?? undefined;
    const projectId = group.eventList[0].projectId ?? undefined;
    const customerId = group.eventList[0].customerId ?? undefined;
    const resellerId = group.eventList[0].resellerId ?? undefined;
    result.push({
      key: [day, uid, projectId, customerId, resellerId].join('-'),
      day,
      uid,
      projectId,
      customerId,
      resellerId,
      eventList: group.eventList,
      lastEvent: group.eventList[0],
    });
  });

  return result;
};

const groupEvents = ({ eventList, groupBy }: Options) => {
  let grouped = groupBy.day
    ? groupByDay(eventList)
    : [{ groupedBy: 'day', name: 'All', eventList, groupList: [] } satisfies GroupedEvent];

  if (groupBy.reseller) {
    grouped = grouped.map((group) => recursiveGroupHelper(group, groupByReseller));
  }

  if (groupBy.customer) {
    grouped = grouped.map((group) => recursiveGroupHelper(group, groupByCustomer));
  }

  if (groupBy.project) {
    grouped = grouped.map((group) => recursiveGroupHelper(group, groupByProject));
  }

  if (groupBy.user) {
    grouped = grouped.map((group) => recursiveGroupHelper(group, groupByUser));
  }

  return grouped;
};

const recursiveGroupHelper = (
  group: GroupedEvent,
  groupFunction: (eventList: KaeplaEvent[]) => GroupedEvent[],
): GroupedEvent => {
  if (group.groupList?.length > 0) {
    return {
      ...group,
      groupList: group.groupList.map((subGroup) => recursiveGroupHelper(subGroup, groupFunction)),
    };
  }

  return { ...group, groupList: groupFunction(group.eventList) };
};

const groupByDay = (eventList: KaeplaEvent[]) => {
  const groupedEvents: Record<string, GroupedEvent> = {};

  eventList.forEach((event) => {
    const day = getDay(event.dateTime);
    if (!groupedEvents[day]) {
      groupedEvents[day] = {
        groupedBy: 'day',
        name: day,
        eventList: [],
        groupList: [],
      };
    }
    groupedEvents[day].eventList.push(event);
  });

  return Object.values(groupedEvents);
};

const groupByUser = (eventList: KaeplaEvent[]) => {
  const groupedEvents: Record<string, GroupedEvent> = {};

  eventList.forEach((event) => {
    const user = event.uid ?? 'Unknown';
    if (!groupedEvents[user]) {
      groupedEvents[user] = {
        groupedBy: 'user',
        name: user,
        eventList: [],
        groupList: [],
      };
    }
    groupedEvents[user].eventList.push(event);
  });

  return Object.values(groupedEvents);
};

const groupByProject = (eventList: KaeplaEvent[]) => {
  const groupedEvents: Record<string, GroupedEvent> = {};

  eventList.forEach((event) => {
    const project = event.projectId ?? 'Unknown';
    if (!groupedEvents[project]) {
      groupedEvents[project] = {
        groupedBy: 'project',
        name: project,
        eventList: [],
        groupList: [],
      };
    }
    groupedEvents[project].eventList.push(event);
  });

  return Object.values(groupedEvents);
};

const groupByCustomer = (eventList: KaeplaEvent[]) => {
  const groupedEvents: Record<string, GroupedEvent> = {};

  eventList.forEach((event) => {
    const customer = event.customerId ?? 'Unknown';
    if (!groupedEvents[customer]) {
      groupedEvents[customer] = {
        groupedBy: 'customer',
        name: customer,
        eventList: [],
        groupList: [],
      };
    }
    groupedEvents[customer].eventList.push(event);
  });

  return Object.values(groupedEvents);
};

const groupByReseller = (eventList: KaeplaEvent[]) => {
  const groupedEvents: Record<string, GroupedEvent> = {};

  eventList.forEach((event) => {
    const reseller = event.resellerId ?? 'Unknown';
    if (!groupedEvents[reseller]) {
      groupedEvents[reseller] = {
        groupedBy: 'reseller',
        name: reseller,
        eventList: [],
        groupList: [],
      };
    }
    groupedEvents[reseller].eventList.push(event);
  });

  return Object.values(groupedEvents);
};
