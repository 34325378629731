import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { requestLog } from '../../Frontend/helpers/logger.js';

interface KOptions {
  params: Record<string, unknown>;
}
export const runSimulationOnCall = ({ params }: KOptions) => {
  requestLog.log(`runSimulation({data:${JSON.stringify(params)}})`);
  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const runSimulation = httpsCallable(functions, 'runSimulation', { timeout: 700_000 });
  void runSimulation(params);
};
