import { GetKaeplaEventDistinctValuesOnCallResponse, KaeplaEventFilter } from '@kaepla/events';
import React from 'react';

export type EventLogFilterColumn = keyof Partial<KaeplaEventFilter>;

export interface AvailableFilterColumn {
  label: string;
  column: EventLogFilterColumn;
  group: 'Top' | 'Others';
}

export interface EventLogColumnConfiguration {
  column: EventLogFilterColumn;
  disabled?: boolean;
  hidden?: boolean;
  defaultValues?: GetKaeplaEventDistinctValuesOnCallResponse;
}

export const EventLogFilterContext = React.createContext({
  eventLogFilters: {} as Partial<KaeplaEventFilter>,
  eventLogAvailableFilterColumns: [] as AvailableFilterColumn[],
  eventLogColumnConfiguration: [] as EventLogColumnConfiguration[],
  disabledFilterColumns: [] as EventLogFilterColumn[],
  hiddenFilterColumns: [] as EventLogFilterColumn[],
  setFilter: (_options: {
    column: EventLogFilterColumn;
    value: string | string[];
    disabled?: boolean;
    hidden?: boolean;
  }) => {
    /** structure */
  },
  clearFilter: (_options: { column: EventLogFilterColumn }) => {
    /** structure */
  },
  clearAllFilters: () => {
    /** structure */
  },
});
