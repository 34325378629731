import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import { clientEventService } from '@kaepla/events';
import { KaeplaDataSnapshot } from '@kaepla/types';
import ArchiveIcon from '@mui/icons-material/ArchiveOutlined';
import SyncIcon from '@mui/icons-material/SettingsBackupRestore';
import {
  Alert,
  Button,
  FormControlLabel,
  Grid2 as Grid,
  LinearProgress,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { useAuth } from '../../../AuthReactProvider';
import { addFirestoreCollectionListener } from '../../../services/firestore/addFirestoreCollectionListener';
import { createNewDataSnapshot } from '../../../services/firestore/createNewDataSnapshot';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { projectAssignmentsState } from '../../../services/recoil/nonpersistent/userAssignmentState';
import { kaeplaAssignmentState } from '../../../services/recoil/persistent/kaeplaAssignmentState';
import { Layout } from '../../Layout/Layout';
import { ProjectDisabled } from '../../features/ProjectDisabled';
import { ProjectLoading } from '../../features/ProjectLoading';

import { DataSnapshotCard } from './DataSnapshotCard';
import { DataSnapshotsHeader } from './DataSnapshotsHeader';

export const DataSnapshots = () => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const projectAssignments = useRecoilValue(projectAssignmentsState);
  const [loading, setLoading] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [showNamedOnly, setShowNamedOnly] = useState(false);
  const [dataSnapshots, setDataSnapshots] = useState<KaeplaDataSnapshot[]>([]);

  const userHasRootAssignment = () => {
    const rootAssignments = projectAssignments.filter(
      (assignment) => assignment.projectId === project.id && assignment.scopePath.length === 0,
    );
    if (rootAssignments.length > 0) return true;

    return false;
  };

  const isProjectOwnerOrAdmin = () => {
    return project?.ownedBy === kaeplaUser?.uid || kaeplaAssignment;
  };

  const createSnapshotNow = () => {
    if (!kaeplaUser) return;
    void createNewDataSnapshot({ project, createdBy: kaeplaUser.uid });
    void clientEventService.createEvent({
      assignmentScope: clientEventService.assignmentScope.PROJECT,
      eventGroup: clientEventService.eventGroup.DATA_SNAPSHOT,
      eventName: clientEventService.eventName.DATA_SNAPSHOT_START_SNAPSHOT,
      uid: kaeplaUser.uid,
      projectId: project.id,
      customerId: project.customerId,
      resellerId: project.resellerId,
    });
  };

  useEffect(() => {
    if (!project?.id) return;
    if (!kaeplaUser?.uid) return;

    setLoading(true);
    const fireStorePath = `projects/${project.id}/dataSnapshots`;
    const queryWhere: WhereClause[] = [
      {
        fieldPath: 'projectId',
        opStr: '==',
        value: project.id,
      },
    ];

    if (!showArchived) {
      queryWhere.push({
        fieldPath: 'archived',
        opStr: '==',
        value: false,
      });
    }

    if (showNamedOnly) {
      queryWhere.push({
        fieldPath: 'name',
        opStr: '!=',
        value: '',
      });
    }

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      orderBy: {
        fieldPath: 'createdAt',
        direction: 'desc',
      },
      limit: 50,
      callback: (data) => {
        const _dataSnapshots = data as KaeplaDataSnapshot[];
        setDataSnapshots(_dataSnapshots);
        setLoading(false);
      },
    });
    return () => {
      unsubscribe();
    };
  }, [kaeplaUser?.uid, project?.id, showArchived, showNamedOnly]);

  if (!project?.id) {
    return <ProjectLoading />;
  }

  if (project?.disabledReason && !kaeplaAssignment) {
    return (
      <Layout>
        <ProjectDisabled reason={project?.disabledReason} />
      </Layout>
    );
  }

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <Grid container spacing={3}>
        <DataSnapshotsHeader />
        {loading && <LinearProgress />}
        <Grid size={12}>
          <Toolbar disableGutters variant="dense">
            {isProjectOwnerOrAdmin() && userHasRootAssignment() && (
              <Button
                variant="contained"
                onClick={() => {
                  createSnapshotNow();
                }}
                endIcon={<SyncIcon />}
              >
                create Snapshot
              </Button>
            )}
            {kaeplaAssignment && (
              <Button
                sx={{ ml: 1 }}
                variant="contained"
                color="secondary"
                onClick={() => {
                  createSnapshotNow();
                }}
                endIcon={<SyncIcon />}
              >
                Admin Create Snapshot
              </Button>
            )}
            <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
            <FormControlLabel
              control={
                <Switch
                  checked={showNamedOnly}
                  size="small"
                  onChange={() => {
                    setShowNamedOnly(!showNamedOnly);
                  }}
                />
              }
              label={`show named only`}
            />
            <Button
              onClick={() => {
                setShowArchived(!showArchived);
              }}
              endIcon={<ArchiveIcon />}
            >
              {showArchived ? `Hide` : `Show`} Archived
            </Button>
          </Toolbar>
        </Grid>
        {dataSnapshots.length === 0 && showNamedOnly && (
          <Grid size={{ xs: 12, md: 6 }}>
            <Alert severity="info">No named data snapshots found</Alert>
          </Grid>
        )}
        {dataSnapshots.map((dataSnapshot) => (
          <DataSnapshotCard key={dataSnapshot.id} dataSnapShot={dataSnapshot} />
        ))}
      </Grid>
    </Layout>
  );
};
