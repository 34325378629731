import { KaeplaUser } from '@kaepla/types';
import { z } from 'zod';

import {
  createKaeplaEventSchema,
  KaeplaEvent,
  kaeplaEventColumnSchema,
  kaeplaEventFilterSchema,
} from '../types';

// createKaeplaEventHttpOnCallFunction

export const createKaeplaEventOnCallSchema = createKaeplaEventSchema.omit({ __firestoreId: true });
export type CreateKaeplaEventOnCallParameters = z.infer<typeof createKaeplaEventOnCallSchema>;
export type CreateKaeplaEventOnCallResponse = true;

// getKaeplaEventListHttpOnCallFunction

export const kaeplaEventListParametersSchema = z.object({
  filterBy: kaeplaEventFilterSchema.optional().nullable(),
  limit: z.number().optional().nullable(),
  offset: z.number().optional().nullable(),
});

export type GetKaeplaEventListOnCallParameters = z.infer<typeof kaeplaEventListParametersSchema>;
export interface GetKaeplaEventListOnCallResponse {
  rows: KaeplaEvent[];
  max: number;
}

// getKaeplaEventDistinctValuesHttpOnCallFunction

export const kaeplaEventDistinctValuesParametersSchema = z.object({
  column: kaeplaEventColumnSchema,
  filterBy: kaeplaEventFilterSchema.optional().nullable(),
});

export type GetKaeplaEventDistinctValuesOnCallParameters = z.infer<
  typeof kaeplaEventDistinctValuesParametersSchema
>;

export interface DistinctValueDefault {
  id: string;
  name: string;
  iconBase64?: string;
}

export type GetKaeplaEventDistinctValuesOnCallResponse =
  | string[]
  | KaeplaUser[]
  | DistinctValueDefault[];
