import { z } from 'zod';

/** KaeplaEventOriginWebAppScreen */

export interface KaeplaEventOriginWebAppScreen {
  availHeight: number;
  availWidth: number;
  colorDepth: number;
  width: number;
  height: number;
  pixelDepth: number;
}

export const kaeplaEventOriginWebAppScreenSchema = z.object({
  availHeight: z.number(),
  availWidth: z.number(),
  colorDepth: z.number(),
  width: z.number(),
  height: z.number(),
  pixelDepth: z.number(),
});

/** KaeplaEventOriginWebApp */

export interface KaeplaEventOriginWebApp {
  originType: 'webApp';
  accessDomain?: string;
  browserInfo?: Record<string, unknown>;
  screen?: KaeplaEventOriginWebAppScreen;
  version?: string;
}

export const kaeplaEventOriginWebAppSchema = z
  .object({
    originType: z.literal('webApp'),
    accessDomain: z.string().optional(),
    browserInfo: z.record(z.unknown()).optional(),
    screen: kaeplaEventOriginWebAppScreenSchema.optional(),
    version: z.string().optional(),
  })
  .strict();

/** KaeplaEventOriginServerFunction */

export interface KaeplaEventOriginServerFunction {
  originType: 'serverFunction';
  functionName?: string;
  region?: string;
  instance?: string;
}

export const kaeplaEventOriginServerFunctionSchema = z
  .object({
    originType: z.literal('serverFunction'),
    functionName: z.string().optional(),
    region: z.string().optional(),
    instance: z.string().optional(),
    isEmulator: z.boolean().optional(),
  })
  .strict();

/** KaeplaEventOrigin */

export type KaeplaEventOrigin = KaeplaEventOriginWebApp | KaeplaEventOriginServerFunction;

export const kaeplaEventOriginSchema = z.discriminatedUnion('originType', [
  kaeplaEventOriginWebAppSchema,
  kaeplaEventOriginServerFunctionSchema,
]);
