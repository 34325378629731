import { KaeplaProject } from '@kaepla/types';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';

import { updateProject } from '../../../../services/firestore/updateProject.js';

import { ImportConfigurationSelect } from './ImportConfigurationSelect.js';

interface Options {
  project: KaeplaProject;
  open: boolean;
  handleClose: () => void;
  handleMenuClose: () => void;
}

const knownImportConfigurations = [
  { name: 'Amount Components', key: 'sandbox-ac' },
  { name: 'Yearly Summaries', key: 'sandbox-ys' },
  { name: 'Amount Components (Demo Org)', key: 'xt-components' },
  { name: 'Amount Components without vehicles (Demo Org)', key: 'xt-comp-wo-vehicles' },
  { name: 'Yearly Summaries (Demo Org)', key: 'xt-yearly-summaries' },
  { name: 'MP (custom)', key: 'marellipoc' },
  { name: 'E (custom)', key: 'elmos' },
  { name: 'Sa (custom)', key: 'saargummi' },
  { name: 'Si (custom)', key: 'signata-ys' },
];

export const ProjectConfigureDialog = ({
  project,
  open,
  handleClose,
  handleMenuClose,
}: Options) => {
  const [updatedAt] = useState(project.updatedAt);
  const [selectedImportConfiguration, setSelectedImportConfiguration] = useState<
    string | undefined
  >(project.importConfigurationId);

  useEffect(() => {
    if (updatedAt !== project.updatedAt) {
      handleClose();
      handleMenuClose();
    }
  }, [handleClose, handleMenuClose, project.updatedAt, updatedAt]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle data-testid="dialog-title">Set the project's import configuration</DialogTitle>
      <DialogContent>
        <DialogContentText data-testid="rename-info">
          Once a configuration is selected, it will take from a couple of minutes up to some hours
          for the project's data to be synched with the new configuration.
        </DialogContentText>
        <Box mt={3}>
          <ImportConfigurationSelect
            importConfigurations={knownImportConfigurations}
            value={selectedImportConfiguration}
            callback={setSelectedImportConfiguration}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button data-testid="button-cancel-rename" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          data-testid="button-rename"
          variant="contained"
          color="error"
          onClick={() => {
            if (!selectedImportConfiguration) return;
            void updateProject({
              project: {
                ...project,
                importConfigurationId: selectedImportConfiguration,
              },
            });
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
