import { DistinctValueDefault, KaeplaEventFilter } from '@kaepla/events';
import { useCallback, useEffect, useState } from 'react';

import { useEventDistinctValuesQuery } from './useEventDistinctValuesQuery';

interface Options {
  eventLogFilters: Partial<KaeplaEventFilter>;
  groupBy: {
    day?: boolean;
    user?: boolean;
    project?: boolean;
    customer?: boolean;
    reseller?: boolean;
  };
}

export const useEventEntryHelperContext = ({ eventLogFilters, groupBy }: Options) => {
  const [resellerOptions, setResellerOptions] = useState<DistinctValueDefault[]>([]);
  const [customerOptions, setCustomerOptions] = useState<DistinctValueDefault[]>([]);
  const [projectOptions, setProjectOptions] = useState<DistinctValueDefault[]>([]);

  const resellerValuesQuery = useEventDistinctValuesQuery({
    parameters: {
      column: 'resellerId',
      filterBy: eventLogFilters,
    },
    isDisabled: !groupBy.reseller,
  });
  useEffect(() => {
    if (resellerValuesQuery.isFetched) {
      setResellerOptions((resellerValuesQuery.data as DistinctValueDefault[]) ?? []);
    }
  }, [resellerValuesQuery.data, resellerValuesQuery.isFetched]);

  const customerValuesQuery = useEventDistinctValuesQuery({
    parameters: {
      column: 'customerId',
      filterBy: eventLogFilters,
    },
    isDisabled: !groupBy.customer,
  });
  useEffect(() => {
    if (customerValuesQuery.isFetched) {
      setCustomerOptions((customerValuesQuery.data as DistinctValueDefault[]) ?? []);
    }
  }, [customerValuesQuery.data, customerValuesQuery.isFetched]);

  const projectValuesQuery = useEventDistinctValuesQuery({
    parameters: {
      column: 'projectId',
      filterBy: eventLogFilters,
    },
    isDisabled: !groupBy.project,
  });
  useEffect(() => {
    if (projectValuesQuery.isFetched) {
      setProjectOptions((projectValuesQuery.data as DistinctValueDefault[]) ?? []);
    }
  }, [projectValuesQuery.data, projectValuesQuery.isFetched]);

  const getResellerInfo = useCallback(
    (resellerId: string | undefined | null) => {
      if (!resellerId) {
        return;
      }
      return resellerOptions.find((option) => option.id === resellerId)?.name ?? resellerId;
    },
    [resellerOptions],
  );

  const getCustomerInfo = useCallback(
    (customerId: string | undefined | null) => {
      if (!customerId) {
        return;
      }
      return customerOptions.find((option) => option.id === customerId)?.name ?? customerId;
    },
    [customerOptions],
  );

  const getProjectInfo = useCallback(
    (projectId: string | undefined | null) => {
      if (!projectId) {
        return;
      }
      return projectOptions.find((option) => option.id === projectId)?.name ?? projectId;
    },
    [projectOptions],
  );

  return {
    getResellerInfo,
    getCustomerInfo,
    getProjectInfo,
  };
};
