import CloseIcon from '@mui/icons-material/Close';
import { Alert, Button, Grid2 as Grid, IconButton, TextField } from '@mui/material';
import { useState } from 'react';

import { useAuth } from '../../../../AuthReactProvider.js';

interface Options {
  setCheckEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const BrandedCheckLoginLinkEmail = ({ setCheckEmail }: Options) => {
  const { authError, clearAuthError } = useAuth();
  const [email, setEmail] = useState<string>('');

  return (
    <Grid
      container
      spacing={3}
      // direction="column"
      // justifyContent="center"
      alignItems="center"
    >
      <Grid size={12}>
        <Alert variant="outlined" severity="info">
          Please enter the email address for which you received the login link.
        </Alert>
      </Grid>
      <Grid size={12}>
        <TextField
          label="Email"
          size="small"
          fullWidth
          sx={{ minWidth: 250 }}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          autoFocus
        />
      </Grid>
      {authError && (
        <Grid size={12}>
          <Alert
            variant="outlined"
            severity="error"
            data-testid="login-BrandedLoginErrorAlert"
            action={
              <IconButton
                aria-label="close"
                data-testid="login-BrandedLoginErrorAlertClose"
                color="inherit"
                size="small"
                onClick={() => {
                  setEmail('');
                  clearAuthError();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {authError.code}
          </Alert>
        </Grid>
      )}
      <Grid size={12}>
        <Button
          variant="contained"
          onClick={() => {
            setCheckEmail(email);
          }}
        >
          Login
        </Button>
      </Grid>
    </Grid>
  );
};
