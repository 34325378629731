import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { IconButton, MenuItem, Paper, Select, Stack } from '@mui/material';
import numbro from 'numbro';
import React from 'react';

interface Properties {
  // pages
  currentPage: number;
  maxPages: number;
  setPage: (value: number) => void;

  // page size
  currentPageSize: number;
  pageSizeOptions: number[];
  setPageSize: (value: number) => void;

  // rows
  rowFrom: number;
  rowTo: number;
  maxRows: number;
}

export const AGPagination: React.FC<Properties> = ({
  currentPage,
  maxPages,
  setPage,
  currentPageSize,
  pageSizeOptions,
  setPageSize,
  rowFrom,
  rowTo,
  maxRows,
}) => {
  return (
    <Paper
      sx={{
        borderTop: '1px solid color(srgb 0.0941176 0.113725 0.121569 / 0.15)',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        padding: 1,
        marginTop: '0 !important',
      }}
      elevation={0}
    >
      <Stack spacing={2} direction={'row'} alignItems={'center'} justifyContent="end">
        <span>
          <label id="pagination-page-size-label">
            Page Size:{' '}
            <Select
              value={currentPageSize}
              label="Age"
              onChange={(event) => {
                setPageSize(Number.parseInt(event.target.value as string, 10));
              }}
              variant="standard"
              labelId="pagination-page-size-label"
              id="pagination-page-size"
            >
              {pageSizeOptions.map((value) => (
                <MenuItem value={value} key={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </label>
        </span>

        <span>
          <strong>{rowFrom}</strong> <span>to</span> <strong>{rowTo}</strong> <span>of</span>{' '}
          <strong>{numbro(maxRows).format({ thousandSeparated: true })}</strong>
        </span>

        <span>
          <IconButton disabled={currentPage <= 1} onClick={() => setPage(1)}>
            <FirstPageIcon />
          </IconButton>
          <IconButton
            disabled={currentPage <= 1}
            onClick={() => setPage(currentPage > 1 ? currentPage - 1 : 1)}
          >
            <NavigateBeforeIcon />
          </IconButton>
          <span>Page</span> <strong>{currentPage}</strong> <span>of</span>{' '}
          <strong>{maxPages}</strong>
          <IconButton
            disabled={currentPage >= maxPages}
            onClick={() => setPage(currentPage < maxPages ? currentPage + 1 : maxPages)}
          >
            <NavigateNextIcon />
          </IconButton>
          <IconButton disabled={currentPage >= maxPages} onClick={() => setPage(maxPages)}>
            <LastPageIcon />
          </IconButton>
        </span>
      </Stack>
    </Paper>
  );
};
