/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line import/no-unassigned-import
import './logging';

import { CssBaseline, LinkProps } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { forwardRef, useMemo, useState } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  Link as RouterLink,
  LinkProps as RouterLinkProperties,
  RouterProvider,
  useNavigation,
} from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { AuthReactProvider, useAuth } from './AuthReactProvider.js';
import { ColorModeContext } from './Frontend/Layout/features/ColorModeToggle.js';
import { Branding } from './Frontend/ListenerComponents/Branding.js';
import { CustomerAssignmentsListener } from './Frontend/ListenerComponents/CustomerAssignmentsListener.js';
import { FiltersListener } from './Frontend/ListenerComponents/FiltersListener.js';
import { KaeplaAssignmentsListener } from './Frontend/ListenerComponents/KaeplaAssignmentsListener.js';
import { LastProjectAndCustomer } from './Frontend/ListenerComponents/LastProjectAndCustomer.js';
import { ProjectAssignmentsListener } from './Frontend/ListenerComponents/ProjectAssignmentsListener.js';
import { ProjectListener } from './Frontend/ListenerComponents/ProjectListener.js';
import { ResellerAssignmentsListener } from './Frontend/ListenerComponents/ResellerAssignmentsListener.js';
import { SimulationFavoritesListener } from './Frontend/ListenerComponents/SimulationFavoritesListener.js';
import { SimulationsListener } from './Frontend/ListenerComponents/SimulationsListener.js';
import { TargetsListener } from './Frontend/ListenerComponents/TargetsListener.js';
import { UserListenerKaeplaAdmin } from './Frontend/ListenerComponents/UserListenerKaeplaAdmin.js';
import { UserListenerUsers } from './Frontend/ListenerComponents/UserListenerUsers.js';
import { Assignments } from './Frontend/Screens/Assignments/Assignments.js';
import { Connectors } from './Frontend/Screens/Connectors/Connectors.js';
import { Customers } from './Frontend/Screens/Customers/Customers.js';
import { DataSnapshots } from './Frontend/Screens/DataSnapshots/DataSnapshots.js';
import { DataSources } from './Frontend/Screens/DataSources/DataSources.js';
import { Disclaimers } from './Frontend/Screens/Disclaimers/Disclaimers.js';
import { ExperimentalAi } from './Frontend/Screens/ExperimentalAi/ExperimentalAi.js';
import { Login } from './Frontend/Screens/Login/Login';
import { Logout } from './Frontend/Screens/Login/Logout.js';
import { MoreLoginOptions } from './Frontend/Screens/Login/features/MoreLoginOptions';
import { Operations } from './Frontend/Screens/Operations/Operations.js';
import { Perspective } from './Frontend/Screens/Perspectives/Perspective.js';
import { Perspectives } from './Frontend/Screens/Perspectives/Perspectives.js';
import { ProjectEvents } from './Frontend/Screens/ProjectEvents/ProjectEvents.js';
import { CloneProject } from './Frontend/Screens/Projects/CloneProject/CloneProject';
import { Projects } from './Frontend/Screens/Projects/Projects.js';
import { ResellerEvents } from './Frontend/Screens/ResellerEvents/ResellerEvents';
import { Resellers } from './Frontend/Screens/Resellers/Resellers.js';
import { Scopes } from './Frontend/Screens/Scopes/Scopes.js';
import { SimulationEditor } from './Frontend/Screens/Simulations/SimulationEditor/SimulationEditor.js';
import { Simulations } from './Frontend/Screens/Simulations/Simulations.js';
import { ImportLog } from './Frontend/Screens/SyncData/ImportLog/ImportLog.js';
import { SyncData } from './Frontend/Screens/SyncData/SyncData.js';
import { Targets } from './Frontend/Screens/Targets/Targets.js';
import { TargetsEditor } from './Frontend/Screens/Targets/TargetsEditor.js';
import { User } from './Frontend/Screens/User/User.js';
import { Users } from './Frontend/Screens/Users/Users.js';
import { ValidateData } from './Frontend/Screens/ValidateData/ValidateData.js';
import { themeOptions } from './Frontend/Theme/KaeplaTheme.js';
import { KaeplaErrorProvider } from './KaeplaErrorProvider.js';
import { UserPreferencesProvider } from './UserPreferencesProvider.js';
import { ErrorBoundary } from './primitive/ErrorBoundary';
import { PageLoading } from './primitive/PageLoading';
import {
  ProtectedRoute,
  RedirectToAppRoute,
  RedirectToProjectEvents,
} from './primitive/ProtectedRoute';
import { KaeplaEventProvider } from './providers/KaeplaEvent.provider';
import { AdminRoute } from './routes/admin/AdminRoute';

const queryClient = new QueryClient();

function useNavigationLoading() {
  const navigation = useNavigation();
  return Boolean(navigation.location);
}

function MainAppContainer() {
  const loading = useNavigationLoading();
  console.log('AppLayout renders', loading);
  return (
    <>
      {/* <LastProjectAndCustomer />
      <KaeplaAssignmentsListener />
      <ResellerAssignmentsListener />
      <CustomerAssignmentsListener />
      <ProjectAssignmentsListener />
      <SimulationsListener showAllSimulationsForProject={true} />
      <SimulationFavoritesListener showAllSimulationsForProject={true} />
      <TargetsListener showAllTargetsForProject={true} />
      <FiltersListener />
      <UserListenerKaeplaAdmin />
      <UserListenerUsers />
      <ProjectListener /> */}
      <Outlet />
    </>
  );
}

const route = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorBoundary scope="root" />} element={<BaseLayout />}>
      <Route
        index
        //
        // when user is not logged in, show the login screen
        //
        path="/"
        element={
          <ProtectedRoute>
            <Projects />
          </ProtectedRoute>
        }
      />

      <Route
        path="/Login"
        element={
          <RedirectToAppRoute>
            <Login />
          </RedirectToAppRoute>
        }
      />
      <Route
        path="/LoginOptions"
        element={
          <RedirectToAppRoute>
            <MoreLoginOptions />
          </RedirectToAppRoute>
        }
      />
      <Route path="/Logout" element={<Logout />} />

      <Route
        path="app"
        lazy={async () => {
          const layout = await import('./routes/app.layout');
          return {
            errorElement: <ErrorBoundary scope="/app" />,
            Component: layout.default,
          };
        }}
      >
        <Route index element={<Navigate to={'/app/project'} />} />
        <Route
          path="project"
          // element={
          //     <ProtectedRoute>
          //       <ProjectRouter/>
          //   </ProtectedRoute>
          // }
          //
          //
          // this is a lazy loaded route. But this can break HMR
          lazy={async () => {
            const router = await import('./routes/project/project.router');
            return {
              errorElement: <ErrorBoundary scope="/app/project" />,
              Component: router.default,
            };
          }}
        />
        {
          //
          //  TODO not found component
          //
        }
        <Route path="*" element={<Navigate to={'/app/project'} />} />
      </Route>
      <Route element={<MainAppContainer />} ErrorBoundary={ErrorBoundary}>
        <Route
          path="/Projects"
          element={
            <ProtectedRoute>
              <Projects />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Project/:projectId"
          element={
            <ProtectedRoute>
              <Perspective />
            </ProtectedRoute>
          }
        />

        <Route
          path="/Assignments"
          element={
            <ProtectedRoute>
              <Assignments />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Assignments/:projectId"
          element={
            <ProtectedRoute>
              <Assignments />
            </ProtectedRoute>
          }
        />

        {/* this is deactivated in the menu */}
        <Route
          path="/ExperimentalAi"
          element={
            <ProtectedRoute>
              <ExperimentalAi />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ExperimentalAi/:projectId"
          element={
            <ProtectedRoute>
              <ExperimentalAi />
            </ProtectedRoute>
          }
        />

        <Route
          path="/Perspectives"
          element={
            <ProtectedRoute>
              <Perspectives />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Perspectives/:projectId"
          element={
            <ProtectedRoute>
              <Perspectives />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Perspective/:projectId"
          element={
            <ProtectedRoute>
              <Perspective />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Perspective/:projectId/:id"
          element={
            <ProtectedRoute>
              <Perspective />
            </ProtectedRoute>
          }
        />

        <Route
          path="/Simulations"
          element={
            <ProtectedRoute>
              <Simulations />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Simulations/:projectId"
          element={
            <ProtectedRoute>
              <Simulations />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Simulation/:projectId/:id"
          element={
            <ProtectedRoute>
              <SimulationEditor />
            </ProtectedRoute>
          }
        />

        <Route
          path="/Targets"
          element={
            <ProtectedRoute>
              <Targets />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Targets/:projectId"
          element={
            <ProtectedRoute>
              <Targets />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Targets/:projectId/:id"
          element={
            <ProtectedRoute>
              <TargetsEditor />
            </ProtectedRoute>
          }
        />

        <Route
          path="/Scopes"
          element={
            <ProtectedRoute>
              <Scopes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Scopes/:projectId"
          element={
            <ProtectedRoute>
              <Scopes />
            </ProtectedRoute>
          }
        />

        <Route path="/Events" element={<Navigate to="/ProjectEvents" />} />
        <Route
          path="/ProjectEvents"
          element={
            <ProtectedRoute>
              <ProjectEvents />
            </ProtectedRoute>
          }
        />
        <Route path="/Events/:projectId" element={<RedirectToProjectEvents />} />
        <Route
          path="/ProjectEvents/:projectId"
          element={
            <ProtectedRoute>
              <ProjectEvents />
            </ProtectedRoute>
          }
        />

        <Route
          path="/Operations"
          element={
            <ProtectedRoute>
              <Operations />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Operations/:projectId"
          element={
            <ProtectedRoute>
              <Operations />
            </ProtectedRoute>
          }
        />

        <Route
          path="/SyncData"
          element={
            <ProtectedRoute>
              <SyncData />
            </ProtectedRoute>
          }
        />
        <Route
          path="/SyncData/:projectId"
          element={
            <ProtectedRoute>
              <SyncData />
            </ProtectedRoute>
          }
        />
        <Route
          path="/SyncData/:projectId/:logId"
          element={
            <ProtectedRoute>
              <ImportLog />
            </ProtectedRoute>
          }
        />

        <Route
          path="/ValidateData"
          element={
            <ProtectedRoute>
              <ValidateData />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ValidateData/:projectId"
          element={
            <ProtectedRoute>
              <ValidateData />
            </ProtectedRoute>
          }
        />

        <Route
          path="/DataSnapshots"
          element={
            <ProtectedRoute>
              <DataSnapshots />
            </ProtectedRoute>
          }
        />
        <Route
          path="/DataSnapshots/:projectId"
          element={
            <ProtectedRoute>
              <DataSnapshots />
            </ProtectedRoute>
          }
        />

        <Route
          path="/DataSources"
          element={
            <ProtectedRoute>
              <DataSources />
            </ProtectedRoute>
          }
        />
        <Route
          path="/DataSources/:projectId"
          element={
            <ProtectedRoute>
              <DataSources />
            </ProtectedRoute>
          }
        />

        <Route
          path="/Disclaimers"
          element={
            <ProtectedRoute>
              <Disclaimers />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/*"
          element={
            <ProtectedRoute>
              <AdminRoute />
            </ProtectedRoute>
          }
        />

        <Route
          path="/Users"
          element={
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          }
        />

        <Route
          path="/User"
          element={
            <ProtectedRoute>
              <User />
            </ProtectedRoute>
          }
        />
        <Route
          path="/User/:action/:token"
          element={
            <ProtectedRoute>
              <User />
            </ProtectedRoute>
          }
        />

        <Route
          path="/Customers"
          element={
            <ProtectedRoute>
              <Customers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Customers/:resellerId"
          element={
            <ProtectedRoute>
              <Customers />
            </ProtectedRoute>
          }
        />

        <Route
          path="/Connectors"
          element={
            <ProtectedRoute>
              <Connectors />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Connectors/:customerId"
          element={
            <ProtectedRoute>
              <Connectors />
            </ProtectedRoute>
          }
        />

        <Route
          path="/Resellers"
          element={
            <ProtectedRoute>
              <Resellers />
            </ProtectedRoute>
          }
        />

        <Route
          path="/ResellerEvents"
          element={
            <ProtectedRoute>
              <ResellerEvents />
            </ProtectedRoute>
          }
        />

        <Route
          path="/CloneProject"
          element={
            <ProtectedRoute>
              <CloneProject />
            </ProtectedRoute>
          }
        />
        <Route
          path="/CloneProject/:projectId"
          element={
            <ProtectedRoute>
              <CloneProject />
            </ProtectedRoute>
          }
        />
      </Route>
    </Route>,
  ),
);

function BaseLayout() {
  console.log('BaseLayout renders');

  //
  // Doesn't move listeners to MainAppContainer
  // Recoil does not revives changes in Layouts
  //
  return (
    <KaeplaEventProvider>
      <RecoilRoot>
        <CssBaseline enableColorScheme />
        <AuthReactProvider>
          <UserPreferencesProvider>
            <KaeplaErrorProvider>
              <Branding />
              <LastProjectAndCustomer />
              <KaeplaAssignmentsListener />
              <ResellerAssignmentsListener />
              <CustomerAssignmentsListener />
              <ProjectAssignmentsListener />
              <SimulationsListener showAllSimulationsForProject={true} />
              <SimulationFavoritesListener showAllSimulationsForProject={true} />
              <TargetsListener showAllTargetsForProject={true} />
              <FiltersListener />
              <UserListenerKaeplaAdmin />
              <UserListenerUsers />
              <ProjectListener />

              <Outlet />
            </KaeplaErrorProvider>
          </UserPreferencesProvider>
        </AuthReactProvider>
      </RecoilRoot>
    </KaeplaEventProvider>
  );
}

const AppWithoutStrictMode = () => {
  const { authState } = useAuth();

  if (authState === null) {
    return <PageLoading />;
  }
  return (
    <RouterProvider
      router={route}
      future={{
        v7_startTransition: true,
      }}
      fallbackElement={<PageLoading />}
    />
  );
};

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProperties, 'to'> & { href: RouterLinkProperties['to'] }
>((properties, reference) => {
  const { href, ...other } = properties;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={reference} to={href} {...other} />;
});

export const App = () => {
  const [mode, setMode] = useState<'light' | 'dark'>('light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((previousMode) => (previousMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme(
        {
          components: {
            MuiTab: {
              defaultProps: {
                component: LinkBehavior,
              },
            },
            MuiMenuItem: {
              defaultProps: {
                component: LinkBehavior,
              },
            },
            MuiLink: {
              defaultProps: {
                component: LinkBehavior,
              } as LinkProps,
            },
            MuiButtonBase: {
              defaultProps: {
                LinkComponent: LinkBehavior,
              },
            },
          },
          palette: {
            mode,
          },
        },
        themeOptions,
      ),
    [mode],
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <AppWithoutStrictMode />
        </ThemeProvider>
      </ColorModeContext.Provider>
    </QueryClientProvider>
  );
};
