/* eslint-disable @typescript-eslint/no-explicit-any */

import { DataType, KaeplaDimension, KaeplaPerspective, KaeplaProject } from '@kaepla/types';
import { ColDef, ColGroupDef } from 'ag-grid-community';

import { KaeplaDataView } from '../../../../../../typings/KaeplaDataView';
import { cleanColumnName, getColumnAbbreviation } from '../../../../../helpers/cleanColumnName';
import { CompareValuesCellRenderer } from '../cellRenderers/CompareValuesCellRenderer';
import { FallbackRenderer } from '../cellRenderers/FallBackRenderer';
import { StringRenderer } from '../cellRenderers/StringRenderer';

const dataTypeMap: Record<string, string> = {
  STRING: 'text',
  BIGNUMERIC: 'number',
  FLOAT64: 'number',
  INT64: 'number',
};

interface Options {
  dimensions: KaeplaDimension[];
  perspective: Partial<KaeplaPerspective> | undefined;
  dataView: KaeplaDataView;
  compareColor?: string;
  project?: KaeplaProject;
}

export const getColumnDefinitions = ({
  dimensions,
  perspective,
  compareColor,
  dataView,
  project,
}: Options) => {
  const perspectiveDimension = perspective?.valueDimension;
  const isTable = dataView === KaeplaDataView.Table;

  const sortedDimensions = [...dimensions]
    .filter((d) => d.visible)
    .sort((a, b) => {
      if (a.columnName === perspectiveDimension && b.columnName !== perspectiveDimension) {
        return -1;
      } else if (a.columnName !== perspectiveDimension && b.columnName === perspectiveDimension) {
        return 1;
        // } else if (a.enableSlicing && !b.enableSlicing) {
        //   return -1;
        // } else if (!a.enableSlicing && b.enableSlicing) {
        //   return 1;
        // } else if (a.enableAggregation && !b.enableAggregation) {
        //   return -1;
        // } else if (!a.enableAggregation && b.enableAggregation) {
        //   return 1;
      } else {
        return a.columnName.localeCompare(b.columnName);
      }
    });

  const columnDefs: (ColDef<any, any> | ColGroupDef<any>)[] = [];

  sortedDimensions.forEach((d) => {
    const base: ColDef<any, any> = {
      field: d.columnName,
      headerName: cleanColumnName(d.columnName),
      cellDataType: dataTypeMap[d.dataType],
      enableRowGroup: d.enableSlicing,
      enableValue: !isTable && d.enableAggregation,
      filter: false,
      valueGetter: (parameters) => {
        const columnId = parameters.column.getColId();
        // handle null values for string columns,
        // the SSRM (getKaeplaProjectData) takes "NULL" as a group key
        // and will treat it as IS NULL in the SQL query
        if (d.dataType === DataType.String && parameters.data[columnId] === null) {
          return 'NULL';
        }
        return parameters.data[columnId];
      },
    };
    let definition: ColDef<any, any> = {};
    if (d.dataType === DataType.String) {
      definition = {
        cellRenderer: StringRenderer,
      } as ColDef<any, any>;
    } else if (d.dataType === DataType.Date) {
      definition = {
        cellRenderer: StringRenderer,
      } as ColDef<any, any>;
    } else if (d.columnName === 'Effect_Year') {
      definition = {
        enablePivot: true,
      } as ColDef<any, any>;
    } else if (
      d.dataType === DataType.Bignumeric ||
      d.dataType === DataType.Float64 ||
      d.dataType === DataType.Int64
    ) {
      definition = {
        aggFunc: isTable ? null : d.enableAggregation && 'sum',
        type: 'rightAligned',
        cellRenderer: CompareValuesCellRenderer,
        cellRendererParams: {
          color: compareColor ?? 'black',
          isCurrency: d.isCurrency,
        },
      } as ColDef<any, any>;
    } else {
      definition = {
        cellRenderer: FallbackRenderer,
      } as ColDef<any, any>;
    }

    // TODO: bring enablePivot into import configuration
    const pivotColumns = [
      'Stage',
      'Product_Classification_Group',
      'Reference_Object__Manufacturer',
      'Reference_Object__Platform',
      'Reference_Object__Production_Brand',
      'Reference_Object__Production_Plant',
      'Reference_Object__Name',
      'Reference_Object__Global_Sales_Segment',
      'Reference_Object__Global_Nameplate',
      'Item_Id',
    ];
    if (pivotColumns.includes(d.columnName)) {
      definition = {
        ...definition,
        enablePivot: true,
        enableRowGroup: true,
      };
    }
    const columnsWithAbbreviation = [
      'Corporate_Turnover',
      'Reference_Object__Manufacturer',
      'Reference_Object__Platform',
      'Reference_Object__Production_Brand',
      'Reference_Object__Production_Plant',
      'Reference_Object__Name',
      'Reference_Object__Global_Sales_Segment',
      'Reference_Object__Global_Nameplate',
      'Reference_Object__Monthly_Volume',
    ];
    if (columnsWithAbbreviation.includes(d.columnName)) {
      definition = {
        ...definition,
        headerName:
          cleanColumnName(d.columnName) + ' (' + getColumnAbbreviation(d.columnName) + ')',
      };
    }
    if (d.columnName === 'Reference_Object__Monthly_Volume') {
      definition = {
        ...definition,
        aggFunc: isTable ? null : d.enableAggregation && 'sum',
        type: 'rightAligned',
        cellRenderer: CompareValuesCellRenderer,
        cellRendererParams: {
          color: compareColor ?? 'black',
          isCurrency: false,
        },
      } as ColDef<any, any>;
    }

    columnDefs.push({ ...base, ...definition });
  });

  // START KAE-536: pivoting
  // Exception TPV
  if (project?.importConfigurationId === 'xt-components') {
    columnDefs.unshift({
      field: 'Turnover_Per_Vehicle',
      headerName: 'Turnover Per Vehicle',
      aggFunc: isTable ? null : 'tpv',
      cellDataType: 'number',
      cellRendererParams: {
        color: compareColor ?? 'black',
        isCurrency: true,
      },
      cellRenderer: CompareValuesCellRenderer,
      type: 'rightAligned',
      enableValue: !isTable,
      enableRowGroup: false,
    });
  }
  // END KAE-536: pivoting

  if (!columnDefs) return [];
  return columnDefs;
};
