import { Box, Tab, Tabs, TabsProps } from '@mui/material';
import React from 'react';

function samePageLinkNavigation(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  if (
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false;
  }
  return true;
}

interface LinkTabProperties {
  label?: string;
  href?: string;
  selected?: boolean;
  value?: string;
}

export function LinkTab(properties: LinkTabProperties) {
  return <Tab aria-current={properties.selected && 'page'} {...properties} />;
}

export function LinkTabs({ children, ...properties }: TabsProps) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // event.type can be equal to focus with selectionFollowsFocus.
    if (
      event.type !== 'click' ||
      (event.type === 'click' &&
        samePageLinkNavigation(event as React.MouseEvent<HTMLAnchorElement, MouseEvent>))
    ) {
      setValue(newValue);
    }
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={handleChange} role="navigation" {...properties}>
        {children}
      </Tabs>
    </Box>
  );
}
