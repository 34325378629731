import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import {
  KaeplaOpsEventType,
  KaeplaOpsUpdateStatus,
  KaeplaSimulation,
  KaeplaSimulationLog,
  KaeplaSimulationPhase,
} from '@kaepla/types';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Collapse,
  Grid2 as Grid,
  Link,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import TimeAgo from 'react-timeago';
import { TypeAnimation } from 'react-type-animation';
import { useRecoilValue } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';

import { useAuth } from '../../../../AuthReactProvider.js';
import { abortSimulationOnCall } from '../../../../services/api/abortSimulation.js';
import { runSimulationOnCall } from '../../../../services/api/runSimulation.js';
import { addFirestoreCollectionListener } from '../../../../services/firestore/addFirestoreCollectionListener.js';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState.js';
import { kaeplaAssignmentState } from '../../../../services/recoil/persistent/kaeplaAssignmentState.js';
import {
  sequence1de,
  sequence2de,
  sequence3de,
  sequence4de,
} from '../../../AI/Assistant/waitingMessageSequencesDe.js';
import {
  sequence1en,
  sequence2en,
  sequence3en,
  sequence4en,
} from '../../../AI/Assistant/waitingMessageSequencesEn.js';
import { ExpandMore } from '../../../features/ExpandMore.js';
import { getDuration } from '../../../helpers/getDuration.js';
import { agoColor } from '../../ExperimentalAi/helpers/agoColor.js';

import { countDownRenderer } from './_features/countDownRenderer.js';
import { isSimulationOutdated } from './_helpers/isSimulationOutdated.js';

const stepMap = {
  clone: 'clone records',
  modify: 'apply parameter changes',
  compute: 'compute calculations',
  deploy: 'provide simulated dataset',
};

const steps = Object.values(KaeplaSimulationPhase);

interface Options {
  own: boolean;
  simulation: KaeplaSimulation;
  preparing: boolean;
  simulationCountDownStart: number;
  setPreparing: Dispatch<SetStateAction<boolean>>;
  approxDuration: number;
}

const sequencesPerLanguage: Record<string, (string | number)[][]> = {
  en: [sequence1en, sequence2en, sequence3en, sequence4en],
  de: [sequence1de, sequence2de, sequence3de, sequence4de],
};

export const SimulationProgress = ({
  own,
  simulation,
  preparing,
  setPreparing,
  simulationCountDownStart,
  approxDuration,
}: Options) => {
  const theme = useTheme();
  const { kaeplaUser } = useAuth();
  const language = (kaeplaUser?.settings?.language as unknown as string) ?? 'en';
  const sequences = sequencesPerLanguage[language];
  // get
  const project = useRecoilValue(projectState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  // local
  const [simulationLogs, setSimulationLogs] = useState<KaeplaSimulationLog[]>([]);
  const [lastLog, setLastLog] = useState<KaeplaSimulationLog>();
  const [activeStep, setActiveStep] = useState(-1);
  const [expanded, setExpanded] = useState(false);

  const unsetPreparing = useDebouncedCallback(() => {
    setPreparing(false);
  }, 1000);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const runtime = () => {
    if (simulation.simulationFinishedAt && simulation.simulationStartedAt) {
      return getDuration(simulation.simulationStartedAt, simulation.simulationFinishedAt);
    }
    return null;
  };

  // this is the simulation log listener
  useEffect(() => {
    if (!project?.id) return;
    if (!simulation?.id) return;
    if (!kaeplaUser?.uid) return;
    if (!kaeplaAssignment) return;

    const fireStorePath = `simulationLogs`;
    const queryWhere: WhereClause[] = [
      {
        fieldPath: 'projectId',
        opStr: '==',
        value: project.id,
      },
      {
        fieldPath: 'simulationId',
        opStr: '==',
        value: simulation.id,
      },
    ];

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      orderBy: {
        fieldPath: 'loggedAt',
        direction: 'desc',
      },
      limit: 1000,
      callback: (data) => {
        const _simulationLogs = data as KaeplaSimulationLog[];
        setLastLog(_simulationLogs[0]);
        if (_simulationLogs?.[0]?.phase) {
          const currentStep = steps.indexOf(_simulationLogs[0].phase);
          setActiveStep(currentStep);
        }
        setSimulationLogs(_simulationLogs);
      },
    });
    return () => {
      unsubscribe();
    };
  }, [simulation.id, kaeplaUser?.uid, project.id, kaeplaAssignment]);

  const isStepFailed = (index: number, _lastLog?: KaeplaSimulationLog) => {
    return index === (_lastLog?.phase ? steps.indexOf(_lastLog.phase) : 0);
  };

  if (!simulation.simulatedAt && !preparing && !simulation.isBeingSimulated) {
    // return null;
  }

  return (
    <Grid size={12}>
      <Card>
        {kaeplaAssignment && (
          <CardContent sx={{ mt: 2 }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const labelProperties: {
                  optional?: React.ReactNode;
                  error?: boolean;
                } = {};
                if (
                  isStepFailed(index, lastLog) &&
                  lastLog?.status === KaeplaOpsUpdateStatus.failed
                ) {
                  labelProperties.optional = (
                    <Typography variant="caption" color="error">
                      failed
                    </Typography>
                  );
                  labelProperties.error = true;
                }
                return (
                  <Step key={label}>
                    <StepLabel {...labelProperties}>{stepMap[label]}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </CardContent>
        )}
        <CardActions disableSpacing>
          {(preparing || simulation.isBeingSimulated) && (
            <CircularProgress size={16} sx={{ mr: 1 }} />
          )}
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            {kaeplaAssignment && simulation.isBeingSimulated && (
              <Box
                sx={{
                  marginY: 'auto',
                }}
              >
                {lastLog && lastLog.eventType === KaeplaOpsEventType.error && (
                  <ErrorIcon sx={{ mr: 1 }} />
                )}
                <Countdown
                  date={simulationCountDownStart + approxDuration}
                  renderer={countDownRenderer}
                />
                <Typography
                  sx={{
                    color: theme.palette[lastLog?.eventType ?? 'info'].light,
                    fontFamily: 'monospace',
                    fontSize: 12,
                  }}
                  component="span"
                >
                  {lastLog?.event}
                </Typography>
              </Box>
            )}
            {/* users */}
            {!kaeplaAssignment && (preparing || simulation.isBeingSimulated) && (
              <Typography
                sx={{
                  fontFamily: 'monospace',
                  color: theme.palette.text.primary,
                }}
              >
                <TypeAnimation
                  sequence={sequences[Math.floor(Math.random() * sequences.length)]}
                  wrapper="span"
                  speed={40}
                  deletionSpeed={90}
                  style={{
                    fontSize: 12,
                    display: 'inline-block',
                    color: theme.palette.text.primary,
                  }}
                  repeat={Number.POSITIVE_INFINITY}
                />
              </Typography>
            )}
            {!preparing && !simulation.isBeingSimulated && simulation.simulatedAt && (
              <Box sx={{ ml: 2 }} component="span" color="text.secondary">
                simulated{' '}
                <TimeAgo date={simulation.simulatedAt.toDate()} max={Number.MAX_SAFE_INTEGER} />
                {runtime() && `, ⟷ ${runtime()}`}
              </Box>
            )}

            {kaeplaAssignment && simulation.isBeingSimulated && (
              <Link
                href="#"
                onClick={() => {
                  unsetPreparing();
                  abortSimulationOnCall({
                    params: {
                      projectId: project.id,
                      simulationId: simulation.id,
                    },
                  });
                }}
              >
                abort
              </Link>
            )}

            {(own || kaeplaAssignment) &&
              !simulation.isBeingSimulated &&
              simulation.simulatedAt &&
              isSimulationOutdated(project, simulation) &&
              project.lastUpdatedAt &&
              simulation.simulatedAt && (
                <>
                  <Box
                    component="span"
                    color="text.secondary"
                    sx={{
                      ml: 2,
                      color: agoColor(theme, simulation.simulatedAt, project.lastUpdatedAt),
                    }}
                  >
                    outdated
                  </Box>
                  <Button
                    component="span"
                    onClick={() => {
                      unsetPreparing();
                      runSimulationOnCall({
                        params: {
                          projectId: project.id,
                          simulationId: simulation.id,
                        },
                      });
                    }}
                  >
                    update now
                  </Button>
                </>
              )}
          </Stack>

          {kaeplaAssignment && (
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          )}
        </CardActions>
        {kaeplaAssignment && (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box
              bgcolor="black"
              color="white"
              p={2}
              maxHeight={200}
              sx={{ overflowY: 'scroll', fontFamily: 'monospace', fontSize: 12 }}
            >
              {simulationLogs.map((log) => (
                <Box key={log.id} sx={{ color: theme.palette[log.eventType].light }}>
                  {log.event}
                </Box>
              ))}
            </Box>
          </Collapse>
        )}
      </Card>
    </Grid>
  );
};
