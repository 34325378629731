import { KaeplaEventFilter } from '@kaepla/events';
import React from 'react';

import {
  AvailableFilterColumn,
  EventLogColumnConfiguration,
  EventLogFilterColumn,
} from '../contexts/EventLogFilter.context';

interface Options {
  initialFilters?: Partial<KaeplaEventFilter>;
  eventLogColumnConfiguration?: EventLogColumnConfiguration[];
}

export const useEventLogFilters = ({
  initialFilters = {},
  eventLogColumnConfiguration = [],
}: Options) => {
  const [eventLogFilters, setEventLogFilters] =
    React.useState<Partial<KaeplaEventFilter>>(initialFilters);

  const [eventLogAvailableFilterColumns] = React.useState<AvailableFilterColumn[]>([
    { column: 'customerId', label: 'Customer', group: 'Top' },
    { column: 'projectId', label: 'Project', group: 'Top' },
    { column: 'resellerId', label: 'Reseller', group: 'Top' },
    { column: 'uid', label: 'User', group: 'Top' },
    { column: 'eventGroup', label: 'Event Group', group: 'Others' },
    { column: 'eventName', label: 'Event Name', group: 'Others' },
    { column: 'logLevel', label: 'Log Level', group: 'Others' },
    { column: 'originType', label: 'Origin Type', group: 'Others' },
    { column: 'perspectiveId', label: 'Perspective ID', group: 'Others' },
    { column: 'scopePathStringified', label: 'Scope Path', group: 'Others' },
    { column: 'simulationId', label: 'Simulation ID', group: 'Others' },
  ]);

  const [disabledFilterColumns, setDisabledFilterColumns] = React.useState<EventLogFilterColumn[]>(
    eventLogColumnConfiguration.filter((c) => c.disabled).map((c) => c.column),
  );
  const [hiddenFilterColumns, setHiddenFilterColumns] = React.useState<EventLogFilterColumn[]>(
    eventLogColumnConfiguration.filter((c) => c.hidden).map((c) => c.column),
  );

  const setFilter = React.useCallback(
    ({
      column,
      value,
      disabled = false,
      hidden = false,
    }: {
      column: EventLogFilterColumn;
      value: string | string[];
      disabled?: boolean;
      hidden?: boolean;
    }) => {
      // cannot clear disabled columns
      if (disabledFilterColumns.includes(column) && value === null) {
        return;
      }

      // cannot override hidden columns
      if (hiddenFilterColumns.includes(column)) {
        return;
      }

      setEventLogFilters((previous) => ({
        ...previous,
        [column]: value,
      }));

      //
      if (disabled) {
        setDisabledFilterColumns((previous) => [...previous, column]);
      }
      if (hidden) {
        setHiddenFilterColumns((previous) => [...previous, column]);
      }
    },
    [disabledFilterColumns, hiddenFilterColumns],
  );

  const clearFilter = React.useCallback(
    ({ column }: { column: EventLogFilterColumn }) => {
      if (disabledFilterColumns.includes(column) || hiddenFilterColumns.includes(column)) {
        return;
      }

      const { [column]: _omitted, ...filtered } = eventLogFilters;

      setEventLogFilters(filtered);
    },
    [disabledFilterColumns, eventLogFilters, hiddenFilterColumns],
  );

  const clearAllFilters = React.useCallback(() => {
    const prepared = {} as typeof eventLogFilters;
    if (disabledFilterColumns.length > 0 || hiddenFilterColumns.length > 0) {
      Object.keys(eventLogFilters).forEach((key_) => {
        const key = key_ as keyof KaeplaEventFilter;
        const value = eventLogFilters[key]!;

        if (disabledFilterColumns.includes(key) || hiddenFilterColumns.includes(key)) {
          prepared[key] = value as unknown as undefined; // not sure why this is necessary?
        }
      });
    }

    setEventLogFilters(prepared);
  }, [disabledFilterColumns, eventLogFilters, hiddenFilterColumns]);

  return {
    eventLogFilters,
    eventLogAvailableFilterColumns,
    disabledFilterColumns,
    hiddenFilterColumns,
    setFilter,
    clearFilter,
    clearAllFilters,
  };
};
