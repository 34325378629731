import { KaeplaEvent } from '@kaepla/events';
import WarnIcon from '@mui/icons-material/WarningAmberOutlined';
import { Grid2 as Grid, Toolbar, Tooltip, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import React from 'react';

import {
  convertISODateTime,
  HumanReadableTimestampType,
} from '../../../../../../Frontend/helpers/convertISODateTime';
import { EventEntryHelperContext } from '../../../../contexts/EventEntryHelper.context';

import { EventGroupIcon } from './EventGroupIcon';

interface Options {
  event: KaeplaEvent;
  groupBy: {
    day?: boolean;
    user?: boolean;
    project?: boolean;
    customer?: boolean;
    reseller?: boolean;
  };
}

export const EventEntry = ({ event, groupBy }: Options) => {
  const { getCustomerInfo, getProjectInfo, getResellerInfo } =
    React.useContext(EventEntryHelperContext);

  const errorMessage = event.payload?.errorMessage as string | undefined;

  const title = React.useMemo(
    () =>
      [
        convertISODateTime({
          dateTime: event.dateTime,
          type: HumanReadableTimestampType.date,
        }),
        groupBy.reseller ? getResellerInfo(event.resellerId) : undefined,
        groupBy.customer ? getCustomerInfo(event.customerId) : undefined,
        groupBy.project ? getProjectInfo(event.projectId) : undefined,
      ]
        .filter((v) => v !== undefined && v !== null && v !== '')
        .join(' - '),
    [
      event.customerId,
      event.dateTime,
      event.projectId,
      event.resellerId,
      getCustomerInfo,
      getProjectInfo,
      getResellerInfo,
      groupBy.customer,
      groupBy.project,
      groupBy.reseller,
    ],
  );

  return (
    <Grid container>
      <Grid size={12}>
        <Tooltip title={event.id}>
          <Typography variant="caption">{title}</Typography>
        </Tooltip>
      </Grid>
      <Grid size={12}>
        <Toolbar sx={{ minHeight: 32 }} disableGutters variant="dense">
          <EventGroupIcon eventGroup={event.eventGroup} />
          <Typography sx={{ ml: 1 }} variant="body2">
            {event.eventName}
          </Typography>
        </Toolbar>
      </Grid>
      {errorMessage && (
        <Grid size={12}>
          <Toolbar sx={{ minHeight: 32 }} disableGutters variant="dense">
            <WarnIcon sx={{ color: red[600] }} fontSize="small" />
            <Typography component="span" variant="body2" sx={{ ml: 1, color: red[600] }}>
              {errorMessage}
            </Typography>
          </Toolbar>
        </Grid>
      )}
    </Grid>
  );
};
