import { Breadcrumbs, Link, Stack } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';

import { Layout } from '../../Frontend/Layout/Layout';
import { useRouteMatch } from '../../hooks/router/useRouteMatch';
import { LinkTab, LinkTabs } from '../../primitive/LinkTabs';

export function AdminLayout() {
  const data = useParams<{ threadId: string }>();
  const routeMatch = useRouteMatch(['/admin/import', '/admin/ai-thread', '/admin/events']);
  let currentTab = routeMatch?.pattern?.path ?? '/admin/import';
  let detailPage = false;

  if (data.threadId) {
    currentTab = '/admin/ai-thread';
    detailPage = true;
  }

  return (
    <Layout>
      <>
        <Stack justifyContent={'center'} spacing={2} height={48}>
          <Breadcrumbs>
            <Link underline="hover" href="/">
              Home
            </Link>
            <Link underline="hover" href="/admin">
              Admin
            </Link>
            {currentTab === '/admin/import' && (
              <Link underline="hover" href="/admin/import">
                Import
              </Link>
            )}
            {currentTab === '/admin/ai-thread' && (
              <Link underline="hover" href="/admin/ai-thread">
                Ai Threads
              </Link>
            )}
            {currentTab === '/admin/events' && (
              <Link underline="hover" href="/admin/events">
                Events
              </Link>
            )}
            {data.threadId && (
              <Link underline="hover" href={`/admin/ai-thread/${data.threadId}`}>
                {data.threadId}
              </Link>
            )}
          </Breadcrumbs>
        </Stack>

        {!detailPage && (
          <LinkTabs value={currentTab}>
            <LinkTab label="Imports" href="/admin/import" value="/admin/import" />
            <LinkTab label="AI Threads" href="/admin/ai-thread" value="/admin/ai-thread" />
            <LinkTab label="Events" href="/admin/events" value="/admin/events" />
          </LinkTabs>
        )}

        <Outlet />
      </>
    </Layout>
  );
}
