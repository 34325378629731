import { clientEventService, GetKaeplaEventListOnCallParameters } from '@kaepla/events';
import { useQuery } from '@tanstack/react-query';

import { useCreateQueryKey } from '../../../../hooks/useCreateQueryKey';

type Options = Pick<GetKaeplaEventListOnCallParameters, 'filterBy'> & {
  batchSize: number;
  page: number;
  refetchInterval?: number;
};

export function useEventListQuery({
  filterBy,
  batchSize,
  page,
  refetchInterval = 60_000,
}: Options) {
  const { queryKey } = useCreateQueryKey('eventList', { ...filterBy, batchSize, page });
  const query = useQuery({
    queryKey,
    queryFn: () => {
      return clientEventService.getEventList({
        filterBy,
        offset: page * batchSize,
        limit: batchSize,
      });
    },
    refetchInterval,
  });

  return query;
}
