import { KaeplaEvent } from '@kaepla/events';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Box } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { knownUsersState } from '../../../../services/recoil/persistent/knownUsersState.js';
import { useGroupEventList } from '../../hooks/useGroupEventList.js';

import { EventGroup } from './features/EventGroup/EventGroup.js';
import { EventUser } from './features/EventUser/EventUser.js';

interface Options {
  eventList: KaeplaEvent[];
  groupBy: {
    day?: boolean;
    user?: boolean;
    project?: boolean;
    customer?: boolean;
    reseller?: boolean;
  };
}

export const EventLogGroupList = ({ eventList, groupBy }: Options) => {
  const knownUsers = useRecoilValue(knownUsersState);

  const eventGroups = useGroupEventList({
    eventList,
    groupBy,
  });

  return (
    <Box>
      {eventGroups.map((group) => (
        <Box key={group.key}>
          <TimelineItem>
            <TimelineOppositeContent color="textSecondary">
              <EventUser uid={group.uid} knownUsers={knownUsers} />
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <EventGroup group={group} groupBy={groupBy} />
          </TimelineItem>
        </Box>
      ))}
    </Box>
  );
};
