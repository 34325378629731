import { Box } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
export const StringRenderer = (properties: ICellRendererParams) => {
  // valueGetter converts null to 'NULL'
  return properties.value === 'NULL' ? (
    <Box sx={{ fontStyle: 'italic', opacity: 0.7 }}>null</Box>
  ) : (
    <Box>{properties.value}</Box>
  );
};
