import { KaeplaEvent } from '@kaepla/events';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface Properties {
  open: boolean;
  onClose: () => void;
  kaeplaEvent?: KaeplaEvent;
}

export const EventDetailModal = ({ onClose, open, kaeplaEvent }: Properties) => {
  return (
    <Dialog data-testid="event-detail-dialog" onClose={onClose} open={open} maxWidth="lg">
      <DialogTitle data-testid="dialog-title">Event Detail {kaeplaEvent?.id}</DialogTitle>

      <DialogContent>
        <Box
          sx={{
            backgroundColor: 'lightgrey',
            fontSize: 12,
            fontFamily: 'Menlo, Consolas, "Droid Sans Mono", monospace',
            fontWeight: 400,
          }}
          padding={2}
        >
          <pre style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(kaeplaEvent, undefined, 2)}</pre>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button data-testid="dialog-invite" onClick={onClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
