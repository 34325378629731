import { Box, Paper, Stack } from '@mui/material';
import React, { useMemo } from 'react';

import { PrimaryButton } from '../../../../primitive/Button/PrimaryButton';
import { SecondaryButton } from '../../../../primitive/Button/SecondaryButton';
import { EventLogFilterColumn, EventLogFilterContext } from '../../contexts/EventLogFilter.context';

import { EventLogAddFilter } from './features/EventLogAddFilter/EventLogAddFilter';
import { EventLogFilterItem } from './features/EventLogFilterItem/EventLogFilterItem';

interface Properties {
  isLoading: boolean;
  isRelative: boolean;
  onRefresh: () => void;
}

export const EventLogFilter: React.FC<Properties> = ({ isLoading, isRelative, onRefresh }) => {
  const { eventLogFilters, disabledFilterColumns, hiddenFilterColumns, clearAllFilters } =
    React.useContext(EventLogFilterContext);

  const [activeFilters, setActiveFilters] = React.useState<EventLogFilterColumn[]>([]);

  const onClick = React.useCallback(() => clearAllFilters(), [clearAllFilters]);

  React.useEffect(() => {
    const selectedFilters = Object.keys(eventLogFilters) as EventLogFilterColumn[];
    setActiveFilters(selectedFilters);
  }, [eventLogFilters]);

  const filterColumns = Object.keys(eventLogFilters) as EventLogFilterColumn[];
  const hasFilters = filterColumns.some(
    (column) => !disabledFilterColumns.includes(column) && !hiddenFilterColumns.includes(column),
  );

  const paperCss = useMemo(
    () =>
      isRelative
        ? {
            padding: 2,
            position: 'relative',
            marginBottom: 1,
            width: '100%',
          }
        : { padding: 1, width: '100%' },
    [isRelative],
  );

  return (
    <Paper sx={paperCss}>
      <Box display="flex" flexWrap="wrap" gap={1}>
        {activeFilters.map((column) =>
          hiddenFilterColumns.includes(column) ? null : (
            <EventLogFilterItem column={column} key={column} />
          ),
        )}

        <Box>
          <EventLogAddFilter activeFilters={activeFilters} setActiveFilters={setActiveFilters} />
        </Box>

        <Box marginLeft="auto" alignSelf="flex-end">
          <Stack spacing={2} direction={'row'} alignItems={'center'}>
            <PrimaryButton onClick={onClick} disabled={!hasFilters || isLoading}>
              Clear Filters
            </PrimaryButton>
            <SecondaryButton loading={isLoading} onClick={onRefresh}>
              Refresh
            </SecondaryButton>
          </Stack>
        </Box>
      </Box>
    </Paper>
  );
};
