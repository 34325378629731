import CloseIcon from '@mui/icons-material/Close';
import { Alert, Button, Grid2 as Grid, IconButton, Link, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';

import { useAuth } from '../../../../AuthReactProvider.js';
import { checkIfEmailIsHandledElseWhere } from '../../../../services/firestore/checkIfEmailIsHandledElseWhere.js';
import { brandingState } from '../../../../services/recoil/persistent/brandingState.js';
import { pageState } from '../../../../services/recoil/persistent/pageState.js';
import { authLog } from '../../../helpers/logger.js';

import { BrandedCheckInviteLinkEmail } from './BrandedCheckInviteLinkEmail.js';
import { BrandedCheckLoginLinkEmail } from './BrandedCheckLoginLinkEmail.js';
import { SecondFactor } from './SecondFactor.js';

export const EmailLoginOptions = () => {
  const {
    signIn,
    sendEmailLink,
    signInWithLink,
    authError,
    clearAuthError,
    clearAuthProcedureError,
    secondFactorRequired,
  } = useAuth();
  const navigate = useNavigate();
  const branding = useRecoilValue(brandingState);
  const [searchParameters] = useSearchParams();
  const action = searchParameters.get('action');
  const [email, setEmail] = useState<string>('');
  const [checkEmail, setCheckEmail] = useState<string | undefined>();
  const [password, setPassword] = useState<string>('');
  const [linkLogin, setLinkLogin] = useState(true);
  const [mustUseSSO, setMustUseSSO] = useState(false);
  const [isPreValidated, setIsPreValidated] = useState(true); // true for autocomplete
  const [loginUrlSuggestion, setLoginUrlSuggestion] = useState<string[]>([]);
  const [loginLinkSent, setLoginLinkSent] = useState(false);
  const [requestInviteEmail, setRequestInviteEmail] = useState(false);
  const setPage = useSetRecoilState(pageState);

  // helps during development, so we don't have to type full email addresses
  const handleKaeplaUsers = (_email: string) => {
    let changedEmail = _email;
    if (!email.includes('@')) {
      changedEmail = `${email}@kaepla.atrigam.com`;
    }
    return changedEmail;
  };

  const onChangeEmail = useDebouncedCallback((changedEmail: string) => {
    // if not a valid email address, don't do anything
    if (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(changedEmail) === false) return;

    const emailIsHandled =
      branding?.authenticationConfiguration?.configuration.handledDomains?.some((domain) =>
        changedEmail.endsWith(domain),
      );

    const getLoginUrlSuggestions = async () => {
      authLog.log(`emailIsHandled ${emailIsHandled}`);
      authLog.log(`getting login url suggestions for ${changedEmail}`);
      setLoginUrlSuggestion([]);
      const emailIsHandledElsWhere = await checkIfEmailIsHandledElseWhere(
        changedEmail,
        globalThis.location.hostname,
      );
      if (emailIsHandledElsWhere) {
        authLog.log(`got login url suggestions for ${changedEmail}`);
        setLoginUrlSuggestion(emailIsHandledElsWhere.customerDomains);
      } else {
        authLog.log(`no login url suggestions for ${changedEmail}`);
        setLoginUrlSuggestion([]);
      }

      if (emailIsHandled) {
        setMustUseSSO(true);
      } else {
        setMustUseSSO(false);
      }
      setIsPreValidated(true);
    };
    void getLoginUrlSuggestions();
  }, 100);

  useEffect(() => {
    if (action === 'signInWithLink' && checkEmail) {
      void signInWithLink(checkEmail);
    } else if (action === 'signInWithInviteLink') {
      setRequestInviteEmail(true);
    }
  }, [action, checkEmail, signInWithLink]);

  const doPreValidatedSignIn = async (preValidatedEmail: string) => {
    authLog.log(`doPreValidatedSignIn with ${preValidatedEmail.toLocaleLowerCase()}`);
    await signIn(preValidatedEmail.toLocaleLowerCase(), password);
    setPage('/');
    navigate('/Login');
  };

  if (!branding) {
    return null;
  }

  if (action === 'signInWithLink' && !checkEmail) {
    return <BrandedCheckLoginLinkEmail setCheckEmail={setCheckEmail} />;
  }

  if (action === 'signInWithInviteLink' && requestInviteEmail) {
    return <BrandedCheckInviteLinkEmail />;
  }

  if (branding && secondFactorRequired) {
    return <SecondFactor />;
  }

  return (
    <form
      onSubmit={(event) => {
        // browser autofill values can only be accessed post-submit for security reasons
        event.preventDefault();
        const form = event.target as HTMLFormElement;
        const loginEmailInput = form.elements.namedItem('loginEmail') as HTMLInputElement;
        const loginEmail = loginEmailInput.value;
        void doPreValidatedSignIn(handleKaeplaUsers(loginEmail));
      }}
    >
      <Grid container spacing={3} alignItems="center">
        {mustUseSSO && (
          <Grid size={12}>
            <Alert variant="outlined" severity="info" data-testid="login-SsoEmailAlert">
              For{' '}
              <b>
                {branding?.authenticationConfiguration?.configuration.handledDomains
                  .filter((domain) => email.endsWith(domain))
                  .map((domain) => `@${domain}`)
                  .join(', ')}
              </b>{' '}
              email addresses, please use Single Sign-On.
            </Alert>
          </Grid>
        )}
        {loginUrlSuggestion && loginUrlSuggestion.length > 0 && (
          <Grid size={12}>
            <Alert
              variant="outlined"
              severity="info"
              data-testid="login-BrandedLoginUrlSuggestionAlert"
            >
              You might want to log in here:
              <br />
              {loginUrlSuggestion.map((url) => (
                <Link key={url} href={`https://${url}`}>
                  {url} {url.endsWith('local') && '(dev)'}
                </Link>
              ))}
            </Alert>
          </Grid>
        )}
        <Grid size={12}>
          <TextField
            inputProps={{ 'data-testid': 'login-BrandedLoginEmail' }}
            label="Email"
            name="loginEmail" // this is needed to trigger onChange on autofill
            size="small"
            fullWidth
            sx={{ minWidth: 250 }}
            value={email}
            onFocus={() => {
              setIsPreValidated(true);
            }}
            onKeyUp={(event) => {
              if (email && event.key === 'Enter') {
                void doPreValidatedSignIn(handleKaeplaUsers(email));
                event.stopPropagation();
              }
            }}
            onChange={(event) => {
              setIsPreValidated(false);
              setEmail(event.target.value);
              onChangeEmail(handleKaeplaUsers(event.target.value));
            }}
            autoFocus
            disabled={loginLinkSent}
          />
        </Grid>
        {!linkLogin && !loginLinkSent && (
          <Grid size={12}>
            <TextField
              inputProps={{ 'data-testid': 'login-BrandedLoginPassword' }}
              label="Password"
              size="small"
              fullWidth
              type={'password'}
              sx={{ minWidth: 250, opacity: linkLogin ? 0.5 : 1 }}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              onFocus={() => {
                setIsPreValidated(true);
              }}
              onKeyUp={(event) => {
                if (email && event.key === 'Enter') {
                  void doPreValidatedSignIn(handleKaeplaUsers(email));
                  event.stopPropagation();
                }
              }}
              disabled={linkLogin || loginLinkSent}
            />
          </Grid>
        )}
        {loginLinkSent && !authError && (
          <Grid size={12}>
            <Alert
              data-testid="login-BrandedLoginLinkSentAlert"
              variant="outlined"
              severity="info"
              action={
                <IconButton
                  data-testid="login-BrandedLoginLinkSentAlertClose"
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setLoginLinkSent(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              An email with a login link is on the way.
            </Alert>
          </Grid>
        )}
        <Grid size={12}>
          <Stack
            sx={{ width: '100%' }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {!linkLogin && (
              <Button
                data-testid="login-BrandedLoginWithPasswordButton"
                variant="text"
                onClick={() => {
                  setLinkLogin(true);
                  clearAuthError();
                  clearAuthProcedureError();
                  setPassword('');
                }}
              >
                Login with Email Link
              </Button>
            )}
            {linkLogin && (
              <Button
                data-testid="login-BrandedLoginSwitchLoginTypeButton"
                variant="text"
                onClick={() => {
                  setIsPreValidated(true);
                  setLinkLogin(false);
                  clearAuthError();
                  clearAuthProcedureError();
                }}
                disabled={loginLinkSent}
              >
                Login
              </Button>
            )}
            {linkLogin && (
              <Button
                data-testid="login-BrandedLoginWithEmailLinkButton"
                variant="contained"
                onClick={() => {
                  void sendEmailLink(email);
                  setLoginLinkSent(true);
                }}
                disabled={
                  loginLinkSent ||
                  !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) ||
                  mustUseSSO ||
                  loginUrlSuggestion.length > 0
                }
              >
                Send Email Login Link
              </Button>
            )}
            {!linkLogin && (
              <Button
                data-testid="login-BrandedLoginWithEmailAndPasswordButton"
                variant="contained"
                type="submit"
                disabled={!isPreValidated || mustUseSSO || loginUrlSuggestion.length > 0}
                // we don't onClick here, see onSubmit in form
              >
                Login
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};
