import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { requestLog } from '../../Frontend/helpers/logger';

interface KOptions {
  params: Record<string, unknown>;
}
export const setDataSnapshotExpirationOnCall = ({ params }: KOptions) => {
  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const setDataSnapshotExpiration = httpsCallable(functions, 'setDataSnapshotExpiration', {
    timeout: 700_000,
  });

  requestLog.log(`setDataSnapshotExpiration({data:${JSON.stringify(params)}})`);
  void setDataSnapshotExpiration(params);
};
