/* eslint-disable @typescript-eslint/naming-convention */
import styled from '@emotion/styled';
import DashboardIcon from '@mui/icons-material/Apps';
import SimulationIcon from '@mui/icons-material/AutoGraphOutlined';
import AssignmentsIcon from '@mui/icons-material/ChecklistOutlined';
import DashboardsIcon from '@mui/icons-material/DashboardOutlined';
import TargetsIcon from '@mui/icons-material/FilterCenterFocusOutlined';
import OperationsIcon from '@mui/icons-material/FunctionsOutlined';
import DataSnapshotsIcon from '@mui/icons-material/History';
import ResellerIcon from '@mui/icons-material/KeyOutlined';
import ScopesIcon from '@mui/icons-material/LayersOutlined';
import AdminIcon from '@mui/icons-material/MonitorHeartOutlined';
import UsersIcon from '@mui/icons-material/People';
import UserIcon from '@mui/icons-material/PersonOutlined';
import ValidateDataIcon from '@mui/icons-material/RuleOutlined';
import CustomerIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import SyncDataIcon from '@mui/icons-material/SyncOutlined';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLessOutlined';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMoreOutlined';
import DataSourcesIcon from '@mui/icons-material/UploadFileOutlined';
import EventsIcon from '@mui/icons-material/ViewTimelineOutlined';
import { Box, Divider, IconButton } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { useAuth } from '../../AuthReactProvider.js';
import { IS_CI } from '../../mode.js';
import { customerAssignmentState } from '../../services/recoil/nonpersistent/customerAssignmentState.js';
import { projectState } from '../../services/recoil/nonpersistent/projectState.js';
import { resellerAssignmentsState } from '../../services/recoil/nonpersistent/resellerAssignmentState.js';
import { projectAssignmentsState } from '../../services/recoil/nonpersistent/userAssignmentState.js';
import { kaeplaAssignmentState } from '../../services/recoil/persistent/kaeplaAssignmentState.js';
import { pageState } from '../../services/recoil/persistent/pageState.js';
import {
  assignmentsColor,
  dataSnapshotColor,
  eventsColor,
  importColor,
  matrixDataColorLight,
  operationsColor,
  perspectivesColor,
  projectsColor,
  scopesColor,
  simulationsColor,
  targetsColor,
  validateColor,
} from '../Screens/defaults';
import { dataSourcesColor } from '../Theme/colors.js';
import { projectAwarePath } from '../helpers/projectAwarePath.js';

interface Options {
  hasScope?: boolean;
}

const StyledListItemButton = styled(({ ...other }) => (
  <ListItemButton data-custom="custom" {...other} />
))`
  &.Mui-selected {
    border-right: 5px solid ${matrixDataColorLight};
  }
`;

export function Menu({ hasScope }: Options) {
  const { kaeplaUser } = useAuth();
  const page = useRecoilValue(pageState);
  const project = useRecoilValue(projectState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const resellerAssignments = useRecoilValue(resellerAssignmentsState);
  const customerAssignments = useRecoilValue(customerAssignmentState);
  const projectAssignments = useRecoilValue(projectAssignmentsState);

  // disable menu expand in the CI for now
  const [toggleMenuExpand, setToggleMenuExpand] = useState(IS_CI ? true : false);

  const handleExpandClick = () => {
    setToggleMenuExpand(!toggleMenuExpand);
  };

  const userHasRootAssignment = () => {
    const rootAssignments = projectAssignments.filter(
      (assignment) => assignment.projectId === project.id && assignment.scopePath.length === 0,
    );
    if (rootAssignments.length > 0) return true;

    return false;
  };

  const isProjectOwnerOrAdmin = () => {
    return project?.ownedBy === kaeplaUser?.uid || kaeplaAssignment;
  };

  return (
    <>
      <StyledListItemButton selected={page === '/Projects'} data-testid="menu-Dashboard" href="/">
        <ListItemIcon>
          <DashboardIcon sx={{ color: projectsColor }} />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </StyledListItemButton>
      {kaeplaAssignment && (
        <StyledListItemButton selected={page === '/Admin'} data-testid="menu-Admin" href="/Admin">
          <ListItemIcon>
            <AdminIcon />
          </ListItemIcon>
          <ListItemText primary="Admin" />
        </StyledListItemButton>
      )}
      {hasScope && (
        <>
          <StyledListItemButton
            selected={page.startsWith('/Assignments')}
            data-testid="menu-Assignments"
            href={projectAwarePath('Assignments', project)}
          >
            <ListItemIcon>
              <AssignmentsIcon sx={{ color: assignmentsColor }} />
            </ListItemIcon>
            <ListItemText primary="Assignments" />
          </StyledListItemButton>

          <StyledListItemButton
            selected={page.startsWith('/Perspective')}
            data-testid="menu-Perspectives"
            href={projectAwarePath('Perspectives', project)}
          >
            <ListItemIcon>
              <DashboardsIcon sx={{ color: perspectivesColor }} />
            </ListItemIcon>
            <ListItemText primary="Dashboards" />
          </StyledListItemButton>
          <StyledListItemButton
            selected={page.startsWith('/Simulation')}
            data-testid="menu-Simulations"
            href={projectAwarePath('Simulations', project)}
          >
            <ListItemIcon>
              <SimulationIcon sx={{ color: simulationsColor }} />
            </ListItemIcon>
            <ListItemText primary="Simulations" />
          </StyledListItemButton>
          <StyledListItemButton
            selected={page.startsWith('/Scope')}
            data-testid="menu-Scopes"
            href={projectAwarePath('Scopes', project)}
          >
            <ListItemIcon>
              <ScopesIcon sx={{ color: scopesColor }} />
            </ListItemIcon>
            <ListItemText primary="Scopes" />
          </StyledListItemButton>
          <StyledListItemButton
            selected={page.startsWith('/Target')}
            data-testid="menu-Targets"
            href={projectAwarePath('Targets', project)}
          >
            <ListItemIcon>
              <TargetsIcon sx={{ color: targetsColor }} />
            </ListItemIcon>
            <ListItemText primary="Targets" />
          </StyledListItemButton>
          <StyledListItemButton
            selected={page === '/DataSnapshots'}
            data-testid="menu-DataSnapshots"
            href={projectAwarePath('DataSnapshots', project)}
          >
            <ListItemIcon>
              <DataSnapshotsIcon sx={{ color: dataSnapshotColor }} />
            </ListItemIcon>
            <ListItemText primary="Data Snapshots" />
          </StyledListItemButton>
        </>
      )}
      <Box sx={{ textAlign: 'right', marginLeft: 'auto', mr: 3 }}>
        <IconButton onClick={handleExpandClick}>
          {toggleMenuExpand ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
        </IconButton>
      </Box>
      {hasScope && (
        <>
          {(toggleMenuExpand || page.startsWith('/ProjectEvents')) && (
            <StyledListItemButton
              selected={page.startsWith('/ProjectEvents')}
              data-testid="menu-project-Events"
              href={projectAwarePath('ProjectEvents', project)}
            >
              <ListItemIcon>
                <EventsIcon sx={{ color: eventsColor }} />
              </ListItemIcon>
              <ListItemText primary="Project Events" />
            </StyledListItemButton>
          )}
          {(toggleMenuExpand || page.startsWith('/Operation')) && (
            <StyledListItemButton
              sx={{ display: 'none' }} // KAE-540: re-implement with importConfiguration
              selected={page.startsWith('/Operation')}
              data-testid="menu-Operations"
              href={projectAwarePath('Operations', project)}
            >
              <ListItemIcon>
                <OperationsIcon sx={{ color: operationsColor }} />
              </ListItemIcon>
              <ListItemText primary="Operations" />
            </StyledListItemButton>
          )}
          {(toggleMenuExpand || page.startsWith('/SyncData')) && isProjectOwnerOrAdmin() && (
            <StyledListItemButton
              selected={page === '/SyncData'}
              data-testid="menu-SyncData"
              href={projectAwarePath('SyncData', project)}
            >
              <ListItemIcon>
                <SyncDataIcon sx={{ color: importColor }} />
              </ListItemIcon>
              <ListItemText primary="Sync Data" />
            </StyledListItemButton>
          )}
          {(toggleMenuExpand || page.startsWith('/ValidateData')) && (
            <StyledListItemButton
              sx={{ display: 'none' }} // KAE-519: re-implement with new validationView
              selected={page === '/ValidateData'}
              data-testid="menu-ValidateData"
              href={projectAwarePath('ValidateData', project)}
            >
              <ListItemIcon>
                <ValidateDataIcon sx={{ color: validateColor }} />
              </ListItemIcon>
              <ListItemText primary="Validate Data" />
            </StyledListItemButton>
          )}
          {(toggleMenuExpand || page.startsWith('/DataSource')) && (
            <Box>
              {(kaeplaAssignment ?? userHasRootAssignment()) && (
                <StyledListItemButton
                  sx={{ display: 'none' }} // this was a POC for a new feature, to be removed soon
                  selected={page === '/DataSources'}
                  data-testid="menu-DataSources"
                  href="/DataSources"
                >
                  <ListItemIcon>
                    <DataSourcesIcon sx={{ color: dataSourcesColor }} />
                  </ListItemIcon>
                  <ListItemText primary="Manage Data Sources" />
                </StyledListItemButton>
              )}
            </Box>
          )}
        </>
      )}

      {toggleMenuExpand && (
        <Box>
          <Divider />
          <StyledListItemButton selected={page === '/Users'} data-testid="menu-Users" href="/Users">
            <ListItemIcon>
              <UsersIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </StyledListItemButton>
          <StyledListItemButton selected={page === '/User'} data-testid="menu-User" href="/User">
            <ListItemIcon>
              <UserIcon />
            </ListItemIcon>
            <ListItemText primary="User" />
          </StyledListItemButton>
          {(customerAssignments.length > 0 || resellerAssignments.length > 0) && (
            <StyledListItemButton
              selected={page === '/Customers'}
              data-testid="menu-Customers"
              href="/Customers"
            >
              <ListItemIcon>
                <CustomerIcon />
              </ListItemIcon>
              <ListItemText primary="Customers" />
            </StyledListItemButton>
          )}
          {(kaeplaAssignment ?? resellerAssignments.length > 0) && (
            <StyledListItemButton
              selected={page === '/Resellers'}
              data-testid="menu-Resellers"
              href="/Resellers"
            >
              <ListItemIcon>
                <ResellerIcon />
              </ListItemIcon>
              <ListItemText primary="Resellers" />
            </StyledListItemButton>
          )}
          {resellerAssignments.length > 0 && (
            <StyledListItemButton
              selected={page === '/ResellerEvents'}
              data-testid="menu-reseller-Events"
              href="/ResellerEvents"
            >
              <ListItemIcon>
                <EventsIcon sx={{ color: eventsColor }} />
              </ListItemIcon>
              <ListItemText primary="Reseller Events" />
            </StyledListItemButton>
          )}
          {kaeplaAssignment?.devTeamMember && <Divider />}
        </Box>
      )}
    </>
  );
}
