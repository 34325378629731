import TimelineContent from '@mui/lab/TimelineContent';
import { Typography } from '@mui/material';
import { useState } from 'react';

import { KaeplaEventGroup } from '../../../../hooks/useGroupEventList';
import { EventEntry } from '../EventEntry/EventEntry';

interface EOptions {
  group: KaeplaEventGroup;
  groupBy: {
    day?: boolean;
    user?: boolean;
    project?: boolean;
    customer?: boolean;
    reseller?: boolean;
  };
}

export const EventGroup = ({ group, groupBy }: EOptions) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <TimelineContent>
      {group.lastEvent && !showMore && (
        <EventEntry key={group.lastEvent.id} event={group.lastEvent} groupBy={groupBy} />
      )}
      <Typography
        sx={{
          textDecoration: 'underline',
          cursor: 'pointer',
          display: showMore || group.eventList.length - 1 === 0 ? 'none' : 'block',
        }}
        variant="caption"
        onClick={() => {
          setShowMore(true);
        }}
      >
        {group.eventList.length - 1} more...
      </Typography>
      {showMore &&
        group.eventList.map((event) => (
          <EventEntry key={event.id} event={event} groupBy={groupBy} />
        ))}
    </TimelineContent>
  );
};
