import { clientEventService, GetKaeplaEventListOnCallParameters } from '@kaepla/events';
import { useInfiniteQuery } from '@tanstack/react-query';

import { useCreateQueryKey } from '../../../hooks/useCreateQueryKey';

const BATCH_SIZE = 100;
const REFETCH_INTERVAL = 60_000; // every minute

type Options = Pick<GetKaeplaEventListOnCallParameters, 'filterBy'>;

export function useEventListInfiniteQuery({ filterBy }: Options) {
  const { queryKey } = useCreateQueryKey('eventList', { ...filterBy });
  const query = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }) => {
      return clientEventService.getEventList({
        filterBy,
        offset: pageParam * BATCH_SIZE,
        limit: BATCH_SIZE,
      });
    },
    initialPageParam: 0,
    getNextPageParam: (_lastPage, _allPages, lastPageNumber) => {
      return lastPageNumber + 1;
    },
    refetchInterval: REFETCH_INTERVAL,
  });

  return query;
}
