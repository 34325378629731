import { Alert, Divider, Grid2 as Grid, Paper, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { EventLogTimeline } from '../../../component/EventLogTimeline/EventLogTimeline.js';
import { projectState } from '../../../services/recoil/nonpersistent/projectState.js';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState.js';
import { kaeplaAssignmentState } from '../../../services/recoil/persistent/kaeplaAssignmentState.js';
import { ProjectDisabled } from '../../features/ProjectDisabled.js';
import { ProjectLoading } from '../../features/ProjectLoading.js';
import { Layout } from '../../Layout/Layout.js';
import { ProjectInfoSmall } from '../Perspectives/features/ProjectSummary/features/ProjectInfoSmall.js';

export const ProjectEvents = () => {
  const project = useRecoilValue(projectState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const currentScopePath = useRecoilValue(currentScopePathState);

  if (!project?.id) {
    return <ProjectLoading />;
  }

  if (project?.disabledReason && !kaeplaAssignment) {
    return (
      <Layout>
        <ProjectDisabled reason={project?.disabledReason} />
      </Layout>
    );
  }

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, sm: 4, md: 3 }}>
                <ProjectInfoSmall title={project.name} />
              </Grid>
              <Grid size={2}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid
                size={{ xs: 12, sm: 6, md: 7 }}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
              >
                <Typography variant="h6">Event Log</Typography>
                {project.currentImportId && (
                  <Alert severity="info">
                    An update is currently in progress, editing currently disabled.
                  </Alert>
                )}
                <Typography variant="body1">View all activities in this project.</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid size={12}>
          {project && (
            <EventLogTimeline
              initialFilters={{
                projectId: project.id,
                customerId: project.customerId,
                resellerId: project.resellerId,
                scopePathStringified: JSON.stringify(currentScopePath),
              }}
              configuration={[
                { column: 'projectId', disabled: true, hidden: true },
                { column: 'customerId', disabled: true, hidden: true },
                { column: 'resellerId', disabled: true, hidden: true },
                { column: 'scopePathStringified', disabled: true, hidden: true },
              ]}
              groupBy={{
                day: true,
                user: true,
              }}
              height={window.innerHeight - 500}
            />
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};
