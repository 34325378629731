/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-base-to-string */
/* eslint-disable @typescript-eslint/naming-convention */
import AddIcon from '@mui/icons-material/AddOutlined';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import { Button, ButtonGroup, FormControl, IconButton } from '@mui/material';
import {
  BasicConfig,
  Config,
  MuiConfig,
  MuiWidgets,
  Operators,
  Settings,
  Types,
} from '@react-awesome-query-builder/mui';
import merge from 'lodash/merge';

const InitialConfig = MuiConfig;
// const {
//   operators: {
//     equal,
//     not_equal,
//     less,
//     less_or_equal,
//     greater,
//     greater_or_equal,
//     like,
//     not_like,
//     starts_with,
//     ends_with,
//     between,
//     not_between,
//     is_null,
//     is_not_null,
//     is_empty,
//     is_not_empty,
//     select_equals, // like `equal`, but for select
//     select_not_equals,
//     select_any_in,
//     select_not_any_in,
//     multiselect_contains,
//     multiselect_not_contains,
//     multiselect_equals, // like `equal`, but for multiselect
//     multiselect_not_equals,
//     proximity, // complex operator with options
//   },
//   types: { text, number, date, time, datetime, select, multiselect, boolean },
// } = InitialConfig;

// remove operators from types
InitialConfig.types.text.excludeOperators = ['proximity'];
InitialConfig.types.date.excludeOperators = ['equal', 'not_equal', 'is_null', 'is_not_null'];
InitialConfig.types.select.excludeOperators = ['is_null', 'is_not_null'];

// add is_empty and is_not_empty operators to select
InitialConfig.types.select.widgets.select.operators = [
  ...(InitialConfig.types.select.widgets.select.operators ?? []),
  'is_empty',
  'is_not_empty',
];

InitialConfig.types.date.widgets.date.operators = [
  ...(InitialConfig.types.date.widgets.date.operators ?? []),
  'is_empty',
  'is_not_empty',
];

const KaeplaTypes: Types = {
  ...InitialConfig.types,
  boolean: merge({}, InitialConfig.types.boolean, {
    widgets: {
      boolean: {
        widgetProps: {
          hideOperator: true,
          operatorInlineLabel: 'is',
        },
        opProps: {
          equal: {
            label: 'is',
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          not_equal: {
            label: 'is not',
          },
        },
      },
    },
  }),
};

const KaeplaOperators: Operators = {
  select_equals: {
    ...InitialConfig.operators.select_equals,
    label: 'Equals',
    labelForFormat: 'Equals',
  },
  select_not_equals: {
    ...InitialConfig.operators.select_not_equals,
    label: 'Not equals',
    labelForFormat: 'Not equals',
  },
};

export const buttonsOnHover = false;

const KaeplaSettings: Settings = {
  showNot: false,
  maxNesting: 2,
  maxNumberOfRules: 20,
  canReorder: false,
  canRegroup: false,
  forceShowConj: true,
  showErrorMessage: true,
  removeEmptyGroupsOnLoad: true,
  setOpOnChangeField: ['keep', 'default'],
  fieldSources: ['field'],
  defaultSelectWidth: '350px',
};

KaeplaSettings.renderButton = (properties) => {
  if (['delRule', 'delGroup'].includes(properties?.type || '')) {
    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      <IconButton {...(properties as any)} size="small" color="default">
        <RemoveIcon fontSize="small" />
      </IconButton>
    );
  } else if (properties?.type === 'addRule') {
    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      <IconButton {...(properties as any)} size="small" color="default">
        <AddIcon fontSize="small" />
      </IconButton>
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <MuiWidgets.MuiButton {...(properties as any)} />;
  // return <Button {...(properties as any)} />;
};

// KaeplaSettings.renderField = (properties) => {
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   return <MuiWidgets.MuiAutocompleteWidget {...(properties as any)} />;
// };

KaeplaSettings.renderConjs = (properties) => {
  if (properties?.conjunctionOptions && properties?.config) {
    const conjsCount = Object.keys(properties.conjunctionOptions).length;
    const { forceShowConj } = properties.config.settings;
    const showConj = forceShowConj ?? conjsCount > 1;

    const onClick = (value: string) => {
      properties.setConjunction(value);
    };

    const renderOptions = () =>
      Object.keys(properties.conjunctionOptions ?? {}).map((key) => {
        if (!properties.conjunctionOptions?.[key]) return;
        const { id, label, checked } = properties.conjunctionOptions[key];
        const postfix = '';
        if ((properties?.readonly ?? properties?.disabled) && !checked) return null;
        return (
          <Button
            key={id + postfix}
            id={id + postfix}
            color={checked ? 'primary' : 'inherit'}
            sx={{ opacity: checked ? 1 : 0.3 }}
            value={key}
            disabled={properties?.readonly ?? properties?.disabled}
            onClick={onClick.bind(null, key)}
          >
            {label}
          </Button>
        );
      });

    return (
      <FormControl>
        <ButtonGroup
          disableElevation
          variant="outlined"
          size="small"
          disabled={properties?.readonly}
        >
          {showConj && renderOptions()}
        </ButtonGroup>
      </FormControl>
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <MuiWidgets.MuiConjs {...(properties as any)} />;
};

export const KaeplaConfig: BasicConfig = {
  ...InitialConfig,
  types: {
    ...InitialConfig.types,
    ...KaeplaTypes,
  },
  operators: {
    ...InitialConfig.operators,
    ...KaeplaOperators,
  },
  settings: {
    ...InitialConfig.settings,
    ...KaeplaSettings,
  },
};

export const filterConfigInit: Config = {
  ...MuiConfig,
  ...KaeplaConfig,
};
